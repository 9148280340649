import { axiosInstance } from "./BaseAPI";

export async function get_vacancy() {
    try {
      return await axiosInstance.get("api/vacancies");
    } catch (error) {
      console.error("Vecancy details Error:", error.response?.data || error);
      throw error;
    }
}

export async function get_vacancy_categories() {
    try {
      return await axiosInstance.get("api/vacancyCategories");
    } catch (error) {
      console.error("Vecancy details Error:", error.response?.data || error);
      throw error;
    }
}

export async function create_vacancy(formData) {
    try {
      return await axiosInstance.post("api/vacancies", formData);
    } catch (error) {
      console.error("Vecancy Create Error:", error.response?.data || error);
      throw error;
    }
  }