import Carousel from "../SharedComponents/carousel";
import ServiceCard from "./Cards/Service Card";
import { images } from "../../assets/images/images";
export default function Services() {
  const serviceArraY = [
    {
      title: "Solution Development",
      image: images.WebDesignSketch,
      content: "We design and deliver tailored software solutions that drive efficiency, enhance functionality, and align with your business goals, ensuring sustainable growth.",
      redirectTo: "/services/1"
    },
    {
      title: "IT Automation",
      image: images.WebDevelopment,
      content:
        "Streamline your operations with cutting-edge IT automation solutions that boost productivity, minimize errors, and optimize resource management across systems.",
      redirectTo: "/services/2"
    },
    {
      title: "Data Warehouse & Business Intelligence",
      image: images.DigitalMarketing,
      content:
        "Empower your decision-making with advanced data warehousing and business intelligence tools, providing actionable insights and fostering data-driven strategies.",
      redirectTo: "/services/3"
    },
  ];
  return (
    <section>
      <div className=" px-[10%]">
        <div className="flex justify-center w-full xl:h-[8rem] ">
          <div className="h-[5rem] xl:h-[8rem] xs:border-l-[3px] border-l-[4px] border-dashed border-black xs:w-[3px] w-[4px] "></div>
        </div>
        <div className="text-center font-medium xs:text-2xl text-3xl sm:text-4xl lg:text-5xl xs:px-0 sm:px-[15%] sm:my-[3%] my-[1rem]">
          We are a top web design company, delivering creative and results-driven solutions.
        </div>
        <div className="flex justify-center w-full xl:h-[8rem] ">
          <div className="h-[5rem] xl:h-[8rem] xs:border-l-[3px] border-l-[4px] border-dashed border-black xs:w-[3px] w-[4px] "></div>
        </div>
      </div>
      <div className="mt-[1rem]  ">
        <div className="lg:hidden">
          <Carousel autoSlide={true}>
            {serviceArraY.map((unit) => (
              <ServiceCard
                title={unit.title}
                picture={unit.image}
                content={unit.content}
                redirectTo={unit.redirectTo}
              />
            ))}
          </Carousel>
        </div>
        <div className="hidden  lg:flex h-[42rem]  mb-[1rem] w-full justify-between items-center px-[10%]">
          {serviceArraY.map((unit) => (
            <ServiceCard
              title={unit.title}
              picture={unit.image}
              content={unit.content}
              redirectTo={unit.redirectTo}
            />
          ))}
        </div>
      </div>
      <div className="flex justify-center w-full h-[5rem] xl:h-[8rem] ">
        <div className="h-[5rem] xl:h-[8rem] xs:border-l-[3px] border-l-[4px] border-dashed border-black xs:w-[3px] w-[4px] "></div>
      </div>
    </section>
  );
}
