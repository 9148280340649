import React, { useEffect, useState } from "react";
import axios from "axios";

const EventsGallerySection = () => {
    const [events, setEvents] = useState([]);

    useEffect(() => {
        fetchEvents();
    }, []);

    const fetchEvents = async () => {
        const response = await axios.get("http://127.0.0.1:8000/api/events");
        setEvents(response.data);
    };

  return (
    <section className='w-full h-[100%] px-[7%] my-10'>
        <div className='w-full h-[20%] text-center my-5 md:my-7 2xl:my-10'>
            <h1 className='font-bold text-[25px] mb-2 2xl:mb-7'>Life at Enigma Solutions</h1>
            <p className='text-[12px] md:text-[15px]'>At Enigma Solutions, we thrive on creativity and innovation, with our team constantly thinking outside the box to deliver fresh, impactful solutions. Every project is approached with a new vision, and our collaborative environment fosters unique ideas that drive success. Explore our gallery to see the moments that capture our team’s spirit and commitment to excellence.</p>
        </div>
        <div className="container mx-auto mt-10">
                {events.length === 0 ? (
                    <p className="text-gray-600">No available events</p>
                ) : (
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                        {events.map((event) => (
                            <div key={event.id} className="relative group overflow-hidden rounded-lg shadow-md">
                                <img
                                    src={event.featured_image}
                                    alt={event.title}
                                    className="w-full h-64 object-cover transition-transform duration-300 group-hover:scale-105"
                                />
                                <div className="absolute inset-0 bg-black bg-opacity-30 flex items-end justify-center px-6 pb-4 transition-all duration-700 ease-in-out delay-100 group-hover:opacity-0">
                                    <h4 className="text-white text-center text-xl font-bold">{event.title}</h4>
                                </div>
                                <div className="absolute inset-0 bg-black bg-opacity-70 flex flex-col items-center justify-center opacity-0 transition-all duration-700 ease-in-out group-hover:opacity-100 p-4">
                                    <p className="text-white text-center text-sm">{event.description}</p>
                                    <button className="border-2 border-solid rounded-[5rem] font-bold border-[#71C25D] text-[#71C25D] px-4 py-2 mt-2">View Album</button>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>

        {/* <div className="sm:hidden w-full">
            <Carousel autoSlide={true}>
                {events.map((item, index) => (
                    <div
                        key={index}
                        className="w-full h-64 overflow-hidden rounded-lg shadow-lg flex justify-center items-center">
                        <img src={item.featured_image} alt={`Event ${index + 1}`} className="w-full h-full object-contain"/>
                    </div>
                ))}
            </Carousel>
        </div> */}
    </section>
  )
}

export default EventsGallerySection
