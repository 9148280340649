import { useState, useEffect } from 'react'; 
import { useNavigate, Link } from 'react-router-dom';
import { Menu } from "@headlessui/react";
import { images } from '../../assets/images/images';
import Dashboard from '../../components/Admin/Dashboard';
import Blogs from '../../components/Admin/Blogs';
import Products from '../../components/Admin/Products';
import Events from '../../components/Admin/Events';
import Careers from '../../components/Admin/Careers';
import { get_admin_profile_details } from '../../API/AdminAPI';
import { removeAuthToken } from '../../Helpers/LocalStorage';

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('dashboard');
  const [userData, setUserData] = useState(null);

  // Fetch user details with authentication check
  useEffect(() => {
    async function fetchUserDetails() {
      try {
        const response = await get_admin_profile_details();
        setUserData(response.data); 
      } catch (error) {
        console.log("User is not authenticated. Redirecting...");
        navigate("/admin");
      }
    }
    fetchUserDetails();
  }, [navigate]);

  if (!userData) {
    return <div>Redirecting to login...</div>; 
  }

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleLogout = async (err) => {
    err.preventDefault();
    try {
      removeAuthToken();
      navigate('/admin'); 
    } catch (error) {
      alert('An error occurred. Please try again..');
    }
  };

  return (
    <div className='w-full h-screen bg-gray-50 font-sans'>
      <div className='w-full h-[10%] flex shadow-md bg-white'>
        <div className='w-[15%] h-full flex justify-center items-center'>
          <img className='w-[60%] h-auto' src="/logo.png" alt="Enigma Solutions"/>
        </div>
        <div className='w-[75%] h-full'></div>
        <Menu as="div" className="relative inline-block w-[12%]">
          <Menu.Button className="flex items-center justify-center focus:outline-none">
            <img
              src={images.AdminProPic}
              alt="Profile"
              className="w-[90px] h-[90px]rounded-full object-cover"
            />
            <span className="text-[20px] font-sans font-bold">
              {userData ? userData.name : "Loading..."}
            </span>
          </Menu.Button>

          <Menu.Items className="absolute right-0 mt-2 w-48 bg-white border rounded-lg shadow-lg">
            <Menu.Item>
              {({ active }) => (
                <Link
                  to={`/users`}
                  className={`block px-4 py-2 text-sm ${active ? "bg-gray-100" : ""}`}
                >
                  Profile
                </Link>
              )}
            </Menu.Item>
            <div className="border-t"></div>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={handleLogout}
                  className={`w-full text-left px-4 py-2 text-sm text-red-500 ${active ? "bg-gray-100" : ""}`}
                >
                  Logout
                </button>
              )}
            </Menu.Item>
          </Menu.Items>
        </Menu>
      </div>

      <div className='w-full flex'>
        <div className='w-[15%] h-screen'>
          <div className="px-5">
            <div 
              className={`flex items-center justify-start w-full h-[60px] p-3 mb-2 rounded-lg cursor-pointer ${
                activeTab === "dashboard" ? "bg-gradient-to-b from-[#0C0B22] via-[#0C162D] to-[#0A0919] text-white" : "text-gray-600 hover:bg-gray-200 hover:text-[#0C162D]"
              }`}
              onClick={() => handleTabChange('dashboard')}
            >
              <span className="text-xl font-medium">Dashboard</span>
            </div>
            <div 
              className={`flex items-center justify-start w-full h-[60px] p-3 mb-2 rounded-lg cursor-pointer ${
                activeTab === "products" ? "bg-gradient-to-b from-[#0C0B22] via-[#0C162D] to-[#0A0919] text-white" : "text-gray-600 hover:bg-gray-200 hover:text-[#0C162D]"
              }`}
              onClick={() => handleTabChange('products')}
            >
              <span className="text-xl font-medium">Products</span>
            </div>
            <div 
              className={`flex items-center justify-start w-full h-[60px] p-3 mb-2 rounded-lg cursor-pointer ${
                activeTab === "blogs" ? "bg-gradient-to-b from-[#0C0B22] via-[#0C162D] to-[#0A0919] text-white" : "text-gray-600 hover:bg-gray-200 hover:text-[#0C162D]"
              }`}
              onClick={() => handleTabChange('blogs')}
            >
              <span className="text-xl font-medium">Blogs</span>
            </div>
            <div 
              className={`flex items-center justify-start w-full h-[60px] p-3 mb-2 rounded-lg cursor-pointer ${
                activeTab === "events" ? "bg-gradient-to-b from-[#0C0B22] via-[#0C162D] to-[#0A0919] text-white" : "text-gray-600 hover:bg-gray-200 hover:text-[#0C162D]"
              }`}
              onClick={() => handleTabChange('events')} 
            >
              <span className="text-xl font-medium">Events</span>
            </div>
            <div 
              className={`flex items-center justify-start w-full h-[60px] p-3 mb-2 rounded-lg cursor-pointer ${
                activeTab === "careers" ? "bg-gradient-to-b from-[#0C0B22] via-[#0C162D] to-[#0A0919] text-white" : "text-gray-600 hover:bg-gray-200 hover:text-[#0C162D]"
              }`}
              onClick={() => handleTabChange('careers')} 
            >
              <span className="text-xl font-medium">Careers</span>
            </div>
          </div>
        </div>

        <div className='w-[85%] p-8 bg-gray-100'>
          {activeTab === 'dashboard' && <Dashboard />}
          {activeTab === 'products' && <Products />}
          {activeTab === 'blogs' && <Blogs />}
          {activeTab === 'events' && <Events />}
          {activeTab === 'careers' && <Careers />}
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
