import React from 'react'
import { images } from '../../assets/images/images'

const OurVisionSection = () => {
  return (
    <section className='w-full h-[100%] flex flex-col justify-center items-center xs:py-0 xs:mb-5 py-5'>
      <div className='w-full h-[70%] flex flex-col justify-center items-center'>
        <div className='w-[90%] h-[20%] text-center py-2'>
            <h1 className='text-[25px] font-bold'>OUR PURPOSE</h1>
        </div>
        <div className='w-[90%] lg:w-[80%] h-[80%] xs:py-2 py-4 lg:py-8 text-gray-700'>
            <p className='text-center xs:text-[10px] text-[14px] md:text-[15px] lg:text-[17px] 2xl:text-[18px] xs:mb-4 mb-6 lg:mb-8'>
            At Enigma Solutions, we are dedicated to driving growth and success through innovation, agility, and collaboration. By aligning technology with business goals, we enable enterprises to overcome challenges, embrace change, and lead in their industries. We believe in fostering meaningful partnerships to create sustainable growth and unlock the full potential of our clients' businesses.
            </p>
        </div>
      </div>
      <div className='w-[90%] lg:w-[80%] h-[30%]'
        style={{
            backgroundImage: `url(${images.AboutUsHeroImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}>
          <div className="w-full h-full  px-5 py-4 text-gray-700 bg-white bg-opacity-80 xs:py-2 lg:py-6">
              <h1 className='flex items-center justify-center text-[22px] xl:text-[24px] font-bold mb-2'>Our Vision</h1>
              <p className='flex items-center justify-center font-medium text-[14px] sm:text-[18px] lg:text-[20px]'>Empowering businesses to lead and succeed in a technology-driven future.</p>
          </div>
      </div>
    </section>
  )
}

export default OurVisionSection
