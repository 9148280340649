import Carousel from "../SharedComponents/carousel";

const WorkFlows = ({ works = [] }) => {
    return (
        <div className="bg-gradient-to-b from-[#0C0B22] via-[#0C162D] to-[#0A0919] w-full h-auto py-8 px-12">
            <div className="text-center text-white font-customFont font-semibold text-4xl mb-6">
                How We Deliver Results
                <hr className="w-[30%] sm:w-[16%] lg:w-[5%] h-1 my-2 bg-gray-400 border-0 mx-auto"></hr>
            </div>
            
            <div className="mt-[1rem] py-8">
                <div className="lg:hidden">
                    <Carousel autoSlide={true}>
                        {works.map((work, index) => (
                            <div key={index} className="flex items-center justify-center">
                                <div className="bg-white rounded-lg shadow-lg p-6 w-60 text-center flex-col items-center justify-center">
                                    <h3 className="font-bold text-black mb-2">{work.name}</h3>
                                    <p className="text-gray-700">{work.desc}</p>
                                </div>
                            </div>
                        ))}
                    </Carousel>
                </div>
                <div className="hidden lg:flex h-[16rem]  mb-[1rem] w-full justify-between space-x-6 items-center px-[10%]">
                    {works.map((work, index) => (
                        <div key={index} className="">
                            <div className="bg-white rounded-lg shadow-lg p-8 text-center flex-col items-center justify-center">
                                <h3 className="font-bold text-black mb-2">{work.name}</h3>
                                <p className="text-gray-700">{work.desc}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default WorkFlows;
