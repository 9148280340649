import { useLocation } from "react-router-dom";
import { useState } from "react";
import axios from "axios";

const ApplicationSubmission = () => {
    const location = useLocation();
    const vacancy = location.state?.vacancy; 

    const [formData, setFormData] = useState({
        fullName: "",
        email: "",
        phone: "",
        description: "",
        noticePeriod: "",
        cv: null,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        }));
    };

    const handleFileChange = (e) => {
        setFormData((prevData) => ({
        ...prevData,
        cv: e.target.files[0],
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();
        formDataToSend.append("fullName", formData.fullName);
        formDataToSend.append("email", formData.email);
        formDataToSend.append("phone", formData.phone);
        formDataToSend.append("description", formData.description);
        formDataToSend.append("noticePeriod", formData.noticePeriod);
        formDataToSend.append("cv", formData.cv);
        formDataToSend.append("vacancy_id", vacancy.id);
    
        try {
            await axios.post("http://127.0.0.1:8000/api/appications", formDataToSend, {
                headers: { "Content-Type": "multipart/form-data" },
            });

            setFormData({
                fullName: "",
                email: "",
                phone: "",
                description: "",
                noticePeriod: "",
                cv: null,
            });
            document.getElementById("cvInput").value = "";

            alert("Application submitted successfully!");
        } catch (error) {
            console.error("Error submitting application:", error);
        }

    }; 

    if (!vacancy) {
        return <p className="text-red-500 text-center mt-10">No job selected!</p>;
    }

  return (
    <div className="min-h-screen">
        <section className="bg-gradient-to-r from-[#0C0B22] via-[#0C162D] to-[#0A0919] text-white pt-28 pb-14 px-6">
            <div className="container mx-auto text-center">
                <h2 className="text-4xl font-bold">Apply for {vacancy.title}</h2>
                <p className="mt-2 text-lg">Your dream job is just one step away!</p>
                <button className="mt-4 px-6 py-2 border-2 border-solid border-white text-white rounded-lg hover:bg-white hover:text-gray-800 transition">
                    Start Application
                </button>
            </div>
        </section>

        <div className="container mx-auto mt-10 py-6">
            <h2 className="text-3xl font-bold">{vacancy.title}</h2>
            <p className="text-gray-700">{vacancy.description}</p>
            <p><strong>Category:</strong> {vacancy.category_name}</p>
            <p><strong>Posted on:</strong> {vacancy.created_at}</p>

            <form onSubmit={handleSubmit} className="mt-6 bg-white p-6 shadow-md rounded-lg">
                
                <label className="block font-semibold mb-2">Full Name: *</label>
                <input 
                    type="text" 
                    name="fullName" 
                    value={formData.fullName} 
                    onChange={handleChange} 
                    required 
                    className="border p-2 w-full mb-4 rounded"
                    placeholder="Enter your full name"
                />

                <label className="block font-semibold mb-2">Email: *</label>
                <input 
                    type="email" 
                    name="email" 
                    value={formData.email} 
                    onChange={handleChange} 
                    required 
                    className="border p-2 w-full mb-4 rounded"
                    placeholder="Enter your email"
                />

                <label className="block font-semibold mb-2">Phone: *</label>
                <input 
                    type="tel" 
                    name="phone" 
                    value={formData.phone} 
                    onChange={handleChange} 
                    required 
                    className="border p-2 w-full mb-4 rounded"
                    placeholder="Enter your phone number"
                />

                <label className="block font-semibold mb-2">Why are you the best candidate for this internship? *</label>
                <textarea 
                    name="description" 
                    value={formData.description} 
                    onChange={handleChange} 
                    required 
                    className="border p-2 w-full mb-4 rounded"
                    placeholder="Explain why you are a great fit for this role"
                    rows="4"
                ></textarea>

                <label className="block font-semibold mb-2">Notice Period: *</label>
                <select 
                    name="noticePeriod" 
                    value={formData.noticePeriod} 
                    onChange={handleChange} 
                    required 
                    className="border p-2 w-full mb-4 rounded">
                    <option value="">Select your notice period</option>
                    <option value="Immediate">Immediate</option>
                    <option value="1 Week">1 Week</option>
                    <option value="2 Weeks">2 Weeks</option>
                    <option value="1 Month">1 Month</option>
                    <option value="More than 1 Month">More than 1 Month</option>
                </select>

                <label className="block font-semibold mb-2">Upload CV: *</label>
                <input
                    type="file"
                    name="cv"
                    id="cv"
                    accept=".pdf,.doc,.docx"
                    onChange={handleFileChange}
                    required
                    className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 
                            file:rounded-lg file:border-0 file:text-sm file:font-semibold 
                            file:bg-gray-100 file:text-gray-700 hover:file:bg-gray-200"
                />
                <button 
                    type="submit" 
                    className="mt-4 px-6 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition"
                >
                    Submit Application
                </button>
            </form>
        </div>
    </div>
  );
};

export default ApplicationSubmission;
