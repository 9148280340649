import { useNavigate } from 'react-router-dom';

export default function ProductCard({ title, picture, content, bgColor, textColor, redirectTo }) {
  const navigate = useNavigate();

  return (
    <div className="w-full flex justify-center cursor-pointer" onClick={() => navigate(redirectTo)}>
      <div className="flex flex-col items-center w-[22rem] px-4 py-4 mb-[1rem] h-[28rem] sm:h-[28rem] lg:h-[28rem] lg:ml-[2rem] lg:justify-center rounded-xl shadow-lg shadow-[#353270]" style={{ backgroundColor: bgColor }}>
        <div className="w-[18rem] h-[40rem] lg:w-full xs:w-auto flex flex-col items-center ">
          <div className="h-[10rem] w-[8rem] flex justify-center items-center">
              <img
                src={picture}
                alt={title}
                className="bg-cover"
              />
          </div>
          <span className="text-center font-medium text-2xl lg:text-2xl xl:text-3xl text-[#00FF57] h-[5rem] lg:h-[7rem] flex items-center">
            {title}
          </span>
          <p className="text-center mb-[1rem] text-xl font-thin" style={{ color: textColor }}>
            {content}
          </p>
        </div>
      </div>
    </div>
  );
}
