import Carousel from "../SharedComponents/carousel";
import { useNavigate } from 'react-router-dom';

const BenefitsSection = ({ benefits }) => {
    const navigate = useNavigate();

    return (
        <div className="w-screen h-auto py-16 px-12 flex-col items-center justify-center">
            <div className="text-black font-customFont font-semibold text-center mb-6 py-8">
                <h1 className="text-4xl">Client Benefits & ROI</h1> 
            </div>

            <div className="mt-[1rem]">
                <div className="sm:hidden">
                    <Carousel autoSlide={true}>
                        {benefits.map((benefit, index) => (
                            <div key={index} className="flex items-center justify-center">
                                <div className="bg-gradient-to-b from-[#0C0B22] via-[#0C162D] to-[#0A0919] rounded-lg shadow-lg w-96 h-56 text-center flex flex-col items-center justify-center px-6 space-y-4">
                                    <h1 className="font-bold text-[#00FF57] text-xl">{benefit.name}</h1>
                                    <p className="text-white">{benefit.desc}</p>
                                </div>
                            </div>
                        ))}
                    </Carousel>
                </div>
                <div className="hidden sm:grid sm:grid-cols-2 xl:grid-cols-3 gap-4">
                    {benefits.map((benefit, index) => (
                        <div key={index} className="flex items-center justify-center">
                            <div className="bg-gradient-to-b from-[#0C0B22] via-[#0C162D] to-[#0A0919] rounded-lg shadow-lg w-[500px] h-56 text-center flex flex-col items-center justify-center px-6 space-y-4">
                                <h1 className="font-bold text-[#00FF57] text-xl md:text-2xl">{benefit.name}</h1>
                                <p className="text-white text-md lg:text-lg">{benefit.desc}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className="w-full flex items-center justify-center mt-12">
                <button onClick={() => navigate('/contact')} type="button" class="px-12 py-[15px] bg-[#1F2732] rounded-[5rem] font-bold text-lg text-[#0EAC3B] hover:scale-105">
                    Free Demo
                </button>
            </div>
        </div>
    );
};

export default BenefitsSection;
