import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Career from "./pages/Career";
import ApplicationSubmission from "./pages/ApplicationSubmission.jsx";
import ServicesPage from "./pages/Services.jsx";
import ServicePage from "./pages/Service.jsx";
import ProductsPage from './pages/Products.jsx'
import ProductPage from './pages/Product.jsx';
import AdminLogin from './pages/Admin/Login.jsx'
import AdminDashboard from './pages/Admin/AdminDashboard.jsx'
import Blog from './pages/Blog.jsx'

export default function AppRouter() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/products" element={<ProductsPage />} />
        <Route path="/services/:serviceId" element={<ServicePage />}/>
        <Route path="/products/:productId" element={<ProductPage />}/>
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/blogs" element={<Blog />} />
        <Route path="/career" element={<Career />} />
        <Route path="/careers/apply" element={<ApplicationSubmission />} />
        <Route path="/admin" element={<AdminLogin />} />
        <Route path="/admin/dashboard" element={<AdminDashboard />} />
      </Routes>
    </Router>
  );
}
