import { images } from "../../assets/images/images";
export default function RoadMap() {

  const RoadmapCard = ({
    number,
    color,
    topic,
    image,
    description,
    position,
  }) => (
    <div
      className={`${position ? "flex-row" : "flex-row-reverse"
        } w-[25rem] lg:w-full lg:flex lg:h-[15rem] lg:items-center lg:justify-between mt-10`}>

      <div
        className={`${position
            ? "lg:flex-row "
            : "lg:flex-row-reverse"
          } flex flex-col lg:flex-row items-center w-[80%] bg-[#646464] px-[2rem] py-[2rem] h-[22rem] lg:h-[18rem] rounded-[20px]`}
        style={{ border: `2px solid ${color}` }}
      >
        <span
          className={`w-full lg:w-[15%] font-bold text-5xl lg:text-[8rem]`}
          style={{ color: color }}
        >
          {number}
        </span>
        
        <div className="w-full lg:w-[65%] flex flex-col px-[2%] lg:px-[5%] py-[10px]">
          <span
            className={`${position ? "lg:text-left" : "lg:text-right"
              } text-[#ffff] text-5xl font-semibold z-20`}
            style={{
              WebkitTextStroke: "2px #1F2732",
            }}
          >
            {topic}
          </span>
          <p
            className={`${position
                ? "lg:text-left"
                : "lg:text-right"
              } text-[#ffff] flex items-center leading-snug text-[18px] lg:text-[17px] 2xl:text-[22px] text-center mt-[1rem] lg:mt-10`}
          >
            {description}
          </p>
        </div>

        <div className="w-[20%] flex items-center justify-center">
          <img
            src={image}
            alt="strategies"
            className="hidden lg:block h-[8rem] w-[8rem] lg:h-[12rem] lg:w-[12rem]"
          />
        </div>
      </div>
    </div>
  );

  return (
    <section className="bg-gradient-to-b from-[#0C0B22] via-[#0C162D] to-[#0A0919] pb-[3rem]">
      <div className="flex justify-center w-full xl:h-[8rem]">
        <div className="h-[5rem] xl:h-[8rem] xs:border-l-[3px] border-l-[4px] border-dashed border-white xs:w-[3px] w-[4px] "></div>
      </div>
      <div className="w-full flex flex-col items-center lg:my-[2rem]">
        <RoadmapCard
          number="01"
          color="#ff9900"
          topic="Strategy"
          image={images.RoadmapStrategies}
          position={true}
          description="We analyze business goals and user needs to craft a roadmap aligned with tailored solutions, IT automation, and data-driven strategies. "
        />
        <div className="lg:flex lg:w-[70%] justify-end">
          <img
            src={images.RoadmapSecondArrow}
            alt=""
            className="h-[10rem] mt-[-2rem] lg:mt-[-4rem]"
          />
        </div>

        <RoadmapCard
          number="02"
          color="#ff0de5"
          topic="Design"
          image={images.RoadmapDesign}
          position={false}
          description="We create intuitive designs and scalable systems that blend aesthetics, functionality, and performance."
        />
        <div className="lg:flex lg:w-[70%] justify-start">
          <img
            src={images.RoadmapFirstArrow}
            alt=""
            className="h-[10rem]  mt-[-2rem] lg:mt-[-4rem]"
          />
        </div>

        <RoadmapCard
          number="03"
          color="#00FFF0"
          topic="Develop"
          image={images.RoadmapDevelop}
          position={true}
          description="Using Agile methodology, we build custom solutions, automate workflows, and implement advanced data systems for seamless functionality."
        />
        <div className="lg:flex lg:w-[70%] justify-end">
          <img
            src={images.RoadmapSecondArrow}
            alt=""
            className="h-[10rem] mt-[-2rem] lg:mt-[-4rem]"
          />
        </div>

        <RoadmapCard
          number="04"
          color="#00FF57"
          topic="Support"
          image={images.RoadmapSupport}
          position={false}
          description="We offer ongoing support to maintain, enhance, and adapt solutions to meet evolving business needs."
        />
      </div>
      <div className="flex justify-center w-full xl:h-[8rem] ">
        <div className="h-[5rem] xl:h-[8rem] xs:border-l-[3px] border-l-[4px] border-dashed border-white xs:w-[3px] w-[4px] "></div>
      </div>
    </section>
  );
}
