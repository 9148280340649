import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import SolutionImage from "../assets/images/solution.png";
import AutomateImage from "../assets/images/automate.png";
import WarehouseImage from "../assets/images/warehouse.png";
import MainLayout from '../components/SharedComponents/MainLayout';

const servicesData = [
  {
    title: 'SOLUTIONS DEVELOPMENT',
    subtitle: 'WE INNOVATE',
    description: 'Transforming business challenges into innovative software solutions, tailored to enhance efficiency, scalability, and success.',
    image: SolutionImage,
    redirectTo: "/services/1"
  },
  {
    title: 'IT AUTOMATION',
    subtitle: 'WE AUTOMATE',
    description: 'Turning your ideas into reality with cutting-edge web solutions that drive brand success and foster growth.',
    image: AutomateImage,
    redirectTo: "/services/2"
  },
  {
    title: 'DATA WAREHOUSE & BUSINESS INTELLIGNECE',
    subtitle: 'WE EMPOWER',
    description: 'Amplify your brand, nurture your audience, and boost sales with data-driven digital marketing strategies.',
    image: WarehouseImage,
    redirectTo: "/services/3"
  },
];

const ServiceCard = ({ title, subtitle, description, image, reverse, redirectTo }) => {
  const navigate = useNavigate();

  return (
    <div
      className={`flex flex-col md:flex-row items-center justify-between gap-16 p-6 my-6 ${reverse ? 'md:flex-row-reverse md:text-right' : ''}`}
    >
      <div className="flex-1 flex justify-center">
        <img src={image} alt={title} className="w-full h-auto object-contain" />
      </div>
      <div className={`text-center md:text-left flex-1 p-2 ${reverse ? 'md:text-right' : ''}`}>
        <h3 className="py-2 text-base font-semibold text-green-600 uppercase">{subtitle}</h3>
        <h2 className="py-2 text-4xl font-bold text-gray-800">{title}</h2>
        <p className="py-2 text-gray-800 mt-2">{description}</p>
        <button
          onClick={() => navigate(redirectTo)}
          className="mt-4 inline-block bg-gradient-to-r from-[#1F2732] to-[#000000] text-white py-3 px-6 rounded-full transition duration-300"
        >
          Explore More
        </button>
      </div>
    </div>
  );
};


const ServicesSection = () => {
  useEffect (()=>{
    window.scrollTo(0,0);
})
  return (
    <MainLayout>
      <div className='py-10'>
        <div className="container mx-auto py-12 px-6">
          <div className="text-center items-center mb-12">
            <h1 className="text-4xl font-bold text-black">OUR SERVICES</h1>
            <hr className="w-[30%] sm:w-[16%] lg:w-[5%] h-1 my-2 bg-gray-400 border-0 mx-auto"></hr>
            <h2 className="text-xl font-semibold text-gray-900 mt-2">What We Do</h2>
            <p className="text-gray-800 mt-4">
              We offer full-funnel digital marketing, web design & development
              services to help businesses gain a competitive edge.
            </p>
          </div>

          <div className="flex justify-center w-full">
            <div className="h-[3rem] xl:h-[4rem] xs:border-l-[2px] border-l-[3px] border-dashed border-black xs:w-[3px] w-[4px]"></div>
          </div>
          <div className="flex flex-col justify-between">
            {servicesData.map((service, index) => (
              <>
                <ServiceCard
                  key={index}
                  title={service.title}
                  subtitle={service.subtitle}
                  description={service.description}
                  image={service.image}
                  redirectTo={service.redirectTo}
                  reverse={index % 2 !== 0}
                />
                {index !== servicesData.length - 1 && (
                  <div className="flex justify-center w-full">
                    <div className="h-[3rem] xl:h-[4rem] xs:border-l-[2px] border-l-[3px] border-dashed border-black xs:w-[3px] w-[4px]"></div>
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default ServicesSection;
