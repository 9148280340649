import { useEffect } from "react";
import MainLayout from "../components/SharedComponents/MainLayout";
import ContactUsHeroSection from "../components/Contact Us Page Components/ContactUsHeroSection";
import SocialMediaIconsSection from "../components/Contact Us Page Components/SocialMediaIconsSection";

export default function ContactUs() {
  useEffect (()=>{
    window.scrollTo(0,0);
})
  return (
    <MainLayout>
      <main>
        <ContactUsHeroSection />
        <SocialMediaIconsSection />
      </main>
    </MainLayout>
  );
}
