import { axiosInstance } from "./BaseAPI";

export async function get_blogs() {
    try {
      return await axiosInstance.get("api/blogs");
    } catch (error) {
      console.error("Blog details Error:", error.response?.data || error);
      throw error;
    }
}

export async function create_blog(formData) {
    try {
      return await axiosInstance.post("api/blogs", formData);
    } catch (error) {
      console.error("Blog Create Error:", error.response?.data || error);
      throw error;
    }
  }