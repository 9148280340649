import HeroSectionBackgroundPhoto from "./Landing Page/heroBgImage.svg";
import MouseIcon from "./Landing Page/mouse-Icon.svg";
import WebDesignSketch from "./Landing Page/Service section/web-design-sketch.svg";
import WebDevelopment from "./Landing Page/Service section/web-development.svg";
import DigitalMarketing from "./Landing Page/Service section/marketing-flow-business.svg";
import RoadmapStrategies from "./Landing Page/RoadMap Section/Strategy.svg";
import RoadmapDesign from "./Landing Page/RoadMap Section/Design.svg";
import RoadmapDevelop from "./Landing Page/RoadMap Section/Develop.svg";
import RoadmapSupport from "./Landing Page/RoadMap Section/Support.png";
import RoadmapFirstArrow from "./Landing Page/RoadMap Section/first_line.svg";
import RoadmapSecondArrow from "./Landing Page/RoadMap Section/second_line.svg";
import AboutUsHeroImage from "./AboutUs/HeroImage.jpg";
import GetinTouchCall from "./Landing Page/Get touch with us section/call.png";
import GetinTouchPen from "./Landing Page/Get touch with us section/pen.png";
import GetinTouchLocation from "./Landing Page/Get touch with us section/location.png";
import Feature1Icon from "./AboutUs/Owenship.svg";
import Feature2Icon from "./AboutUs/Think.svg";
import Feature3Icon from "./AboutUs/Ethic.svg";
import EventImage from "./AboutUs/Events.jpg";
import CareerHeroPicture from "./Career page/career_hero_section.jpg";
import ContactUsBackground from "./ContactUs/ContactUsBackground.svg";
import FacebookIcon from "./ContactUs/Facebook.png";
import LinkedinIcon from "./ContactUs/LinkedIn.png";
import TwitterIcon from "./ContactUs/Twitter.png";
import GoogleIcon from "./ContactUs/Google.png";
import NotAvailableImage from "./disapproval.png";
import GmailIcon from "./gmailIcon.svg";
import Finnovate from './Products/Finnovate.png';
import Seasys from './Products/Seasys.png';
import Plateria from './Products/Plateria.png';
import Finnovesta from './Products/Finnovesta.png';
import RewardHub from './Products/RewardHub.png';
import Effiniti from './Products/Effiniti.png';
import Bookova from './Products/Bookova.png';
import Edura from './Products/Edura.png';
import AdminProPic from './Admin/User.png'
import FinnovateDashboard from './Products/Dashboards/Finnovate.png';
import SeasysDashboard from './Products/Dashboards/Seasys.png';
import PlateriaDashboard from './Products/Dashboards/Plateria.png';
import FinnovestaDashboard from './Products/Dashboards/Finnovesta.png';
import RewardHubDashboard from './Products/Dashboards/RewardHub.png';
import EffinitiDashboard from './Products/Dashboards/Effiniti.png';
import BookovaDashboard from './Products/Dashboards/Bookova.png';
import EduraDashboard from './Products/Dashboards/Edura.png';

export const images = {
  HeroSectionBackgroundPhoto,
  MouseIcon,
  WebDesignSketch,
  WebDevelopment,
  DigitalMarketing,
  RoadmapStrategies,
  RoadmapDesign,
  RoadmapDevelop,
  RoadmapSupport,
  RoadmapFirstArrow,
  RoadmapSecondArrow,
  AboutUsHeroImage,
  GetinTouchCall,
  GetinTouchLocation,
  GetinTouchPen,
  Feature1Icon,
  Feature2Icon,
  Feature3Icon,
  EventImage,
  CareerHeroPicture,
  ContactUsBackground,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  GoogleIcon,
  NotAvailableImage,
  GmailIcon,
  Finnovate,
  Seasys,
  Plateria,
  Finnovesta,
  RewardHub,
  Effiniti,
  Bookova,
  Edura,
  FinnovateDashboard,
  SeasysDashboard,
  PlateriaDashboard,
  FinnovestaDashboard,
  RewardHubDashboard,
  EffinitiDashboard,
  BookovaDashboard,
  EduraDashboard,
  AdminProPic,
};
