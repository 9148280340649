import { React, useEffect, useState } from "react";
import { Eye, EyeOff } from "lucide-react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import { admin_login } from "../../API/AdminAPI";
import {
  getCredentials,
  removeCredentials,
  setAuthToken,
  setCredentials,
} from "../../Helpers/LocalStorage";

const LoginPage = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [rememberToken, setRememberToken] = useState(false);

  useEffect(() => {
    const { email, password } = getCredentials();
    if (email && password) {
      setRememberToken(true);
      setFormData({
        email: email,
        password: password,
      });
    }
  }, []);

  const onChangeRemember = (e) => {
    setRememberToken(e.target.checked);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
      e.preventDefault();
      console.log("Submitting login:", formData);

      try {
          const response = await admin_login(formData);
          console.log("Login Response:", response.data);

          if (response.status === 200) {
              if (rememberToken) {
                  setCredentials(formData.email, formData.password);
              } else {
                  removeCredentials();
              }
              setAuthToken(response.data.token);
              
              if (response.data.user_type === "admin") {
                  navigate("/admin/dashboard");
              } else {
                  toast.error("Access denied.");
              }
          } else {
              toast.error("Login failed. Please check your credentials.");
          }
      } catch (error) {
          console.error("Login Error:", error.response?.data || error);

          if (error.response?.status === 401) {
              toast.error("Invalid email or password. Please try again.");
          } else if (error.response?.status === 403) {
              toast.error("Access denied. You are not an admin.");
          } else {
              toast.error("Something went wrong. Try again.");
          }
      }
  };

  return (
    <div className="flex justify-center items-center h-[100vh] w-full bg-background">
      <div className="bg-[#ffff] rounded-lg shadow-lg w-[18rem] sm:w-auto px-[2rem] sm:px-[4rem] pt-[2rem] pb-[3rem] ">
        <h2 className="text-center font-bold text-[30px] text-[#1F2732] mb-5">Login</h2>
        <form onSubmit={onSubmit}>
          <div>
            <input
              className="bg-gray-200 w-full h-[2.5rem] sm:h-[3.5rem] pl-[1rem] rounded-md mb-6"
              placeholder="Email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              type="email"
            />
            <span className="relative ">
              <input
                className="bg-gray-200 w-full h-[2.5rem] sm:h-[3.5rem] pl-[1rem] rounded-md mb-3"
                placeholder="Password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                type={showPassword ? "text" : "password"}
              />
              <button
                type="button"
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500"
                onClick={() => setShowPassword((prev) => !prev)}
              >
                {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
              </button>
            </span>
            <div className="flex w-full justify-between h-[2rem] items-center">
              <span className="h-[2rem] flex items-center">
                <input
                  type="checkbox"
                  name="remember_icon"
                  id="remember_icon"
                  checked={rememberToken}
                  onChange={onChangeRemember}
                />
                <span className=" ml-2">
                  Remember me
                </span>
              </span>
              <Link
                to="/forgot-password"
                className="text-[#1F2732]"
              >
                Forget Password?
              </Link>
            </div>
            <button
              type="submit"
              className='w-full py-4 rounded-[20px] bg-gradient-to-r from-[#1F2732] to-[#000000] text-white mt-12'
            >
              Sign In
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
