import axios from "axios";
import { getAuthToken, removeAuthToken } from "../Helpers/LocalStorage";
const Base_Url = process.env.REACT_APP_BACKEND_URL;

const axiosInstance = axios.create({
  baseURL: Base_Url, 
  withCredentials: true,
});

// Request interceptor: Add token to headers if available
axiosInstance.interceptors.request.use(
  (config) => {
    const token = getAuthToken(); 
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`; 
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor: Check for authentication status
axiosInstance.interceptors.response.use(
    (response) => response, 
    (error) => {
      if (error.response && error.response.status === 401) {
        console.log("User session expired. Redirecting to login...");
        removeAuthToken(); // Remove expired token
        window.location.href = "/admin"; // Redirect to login page
      }
      return Promise.reject(error);
    }
  );  

export { axiosInstance };
