import React, { useEffect, useState } from "react";
import axios from "axios";
import { ImCross } from "react-icons/im";

const Applications = ({ vacancy }) => {
    const [loading, setLoading] = useState(true);
    const [applications, setApplications] = useState([]);
    const [categories, setCategories] = useState([]);
    const [useNewCategory, setUseNewCategory] = useState(false);
    const [title, setTitle] = useState("");
    const [categoryName, setCategoryName] = useState("");
    const [description, setDescription] = useState("");
    const [showForm, setShowForm] = useState(false);
    const [formData, setFormData] = useState({
        title: "",
        categoryName: "",
        description: "",
    });

    useEffect(() => {
        fetchApplications();
        fetchCategories();
    }, [vacancy.id]);

    const fetchApplications = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`http://127.0.0.1:8000/api/applications/${vacancy.id}`);
            setApplications(response.data);
        } catch (error) {
            console.error("Error fetching applications:", error);
        } finally {
            setLoading(false);
        }
    };

    const fetchCategories = async () => {
        const response = await axios.get("http://127.0.0.1:8000/api/vacancyCategories");
        setCategories(response.data);
      };

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
  
    const handleCategoryChange = (e) => {
        const selectedValue = e.target.value;
        if (selectedValue === "new") {
          setUseNewCategory(true); 
          setFormData({ ...formData, categoryName: "" });
        } else {
          setUseNewCategory(false); 
          setFormData({ ...formData, categoryName: selectedValue });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("title", title);
        formData.append("description", description);
        formData.append("categoryName", categoryName);

        await axios.post("http://127.0.0.1:8000/api/applications", formData, {
            headers: { "Content-Type": "multipart/form-data" },
        });

        fetchApplications();
        setTitle("");
        setCategoryName("");
        setDescription("");
        setShowForm(false); 
    };

    const handleCancel = async (e) => {
        e.preventDefault();
        setShowForm(false);
        setUseNewCategory(false);
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
    
        return date.toLocaleString('en-US', { 
            year: 'numeric', 
            month: '2-digit', 
            day: '2-digit', 
            hour: '2-digit', 
            minute: '2-digit', 
            second: '2-digit', 
            hour12: true 
        });
    };

    const handleEdit = (vacancy) => {
        setTitle(vacancy.title);
        setCategoryName(vacancy.category_name);
        setDescription(vacancy.description);
        setShowForm(true);
    };

    return (
        <div className="min-h-screen bg-gray-50 p-6">
            <div className="container mx-auto bg-white p-8 rounded-lg shadow-md">
                <h2 className="text-3xl font-semibold text-gray-800 mb-6">Manage Applications</h2>

                {!showForm && (
                    <button
                        onClick={() => handleEdit(vacancy)}
                        className="py-2 px-4 bg-[#0C162D] bg-opacity-70 text-white font-semibold rounded-lg hover:bg-[#0C162D] focus:outline-none focus:ring-2 focus:ring-[#0e1933]"
                    >
                        Edit Vacancy
                    </button>
                )}

                {showForm && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                        <div className="bg-white p-8 rounded-lg shadow-md w-1/3">
                            <div className="flex justify-between">
                                <h3 className="text-2xl font-semibold text-gray-800 mb-6">Edit Vacancy</h3>
                                <ImCross onClick={() => setShowForm(false)} className="cursor-pointer" />
                            </div>
                            <form onSubmit={handleSubmit}>
                                <label className="block mb-2">Title:</label>
                                <input
                                    type="text"
                                    name="title"
                                    value={title}
                                    onChange={handleInputChange}
                                    required
                                    className="w-full border p-2 rounded mb-3"
                                />

                                <label className="block mb-2">Category:</label>
                                {!useNewCategory ? (
                                    <select
                                    name="categoryName"
                                    onChange={handleCategoryChange}
                                    className="w-full border p-2 rounded mb-3"
                                    value={categoryName}
                                    >
                                    <option value="">Select a category</option>
                                    {categories.map((category) => (
                                        <option key={category.id} value={category.category_name}>
                                        {category.category_name}
                                        </option>
                                    ))}
                                    <option value="new">+ Add New Category</option>
                                    </select>
                                ) : (
                                    <input
                                    type="text"
                                    name="categoryName"
                                    value={categoryName}
                                    onChange={handleInputChange}
                                    placeholder="Enter new category"
                                    className="w-full border p-2 rounded mb-3"
                                    required
                                    />
                                )}

                                <label className="block mb-2 mt-3">Description:</label>
                                <textarea
                                    name="description"
                                    value={description}
                                    onChange={handleInputChange}
                                    required
                                    className="w-full border p-2 rounded mb-3"
                                ></textarea>

                                <div className="flex justify-end gap-2">
                                    <button
                                    type="button"
                                    onClick={handleCancel}
                                    className="bg-gray-400 text-white px-4 py-2 rounded"
                                    >
                                    Cancel
                                    </button>
                                    <button
                                    type="submit"
                                    className="bg-green-600 text-white px-4 py-2 rounded"
                                    >
                                    Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                )}
            </div>

            <div className="mt-6 overflow-x-auto">
                <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md">
                    <thead className="bg-gray-100">
                        <tr>
                            <th className="px-6 py-3 text-left text-md font-semibold text-gray-800">Name</th>
                            <th className="px-6 py-3 text-left text-md font-semibold text-gray-800">Email</th>
                            <th className="px-6 py-3 text-left text-md font-semibold text-gray-800">Mobile Number</th>
                            <th className="px-6 py-3 text-left text-md font-semibold text-gray-800">Description</th>
                            <th className="px-6 py-3 text-left text-md font-semibold text-gray-800">Notice Period</th>
                            <th className="px-6 py-3 text-left text-md font-semibold text-gray-800">Uploaded CV</th>
                            <th className="px-6 py-3 text-left text-md font-semibold text-gray-800">Submission Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {applications && applications.length > 0 ? (
                            applications.map((application, index) => (
                                <tr key={index} className="border-t">
                                    <td className="px-6 py-3 text-sm text-gray-700">{application.name}</td>
                                    <td className="px-6 py-3 text-sm text-gray-700">{application.email}</td>
                                    <td className="px-6 py-3 text-sm text-gray-700">{application.phone}</td>
                                    <td className="px-6 py-3 text-sm text-gray-700">{application.description}</td>
                                    <td className="px-6 py-3 text-sm text-gray-700">{application.notice_period}</td>
                                    <td className="px-6 py-3 text-sm text-gray-700"> {application.cv_url ? (
                                            <a href={application.cv_url} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                                            {application.cv_original_name || "Download CV"}
                                        </a>
                                        ) : (
                                            "No CV Uploaded"
                                        )}
                                    </td>
                                    <td className="px-6 py-3 text-sm text-gray-700">{formatDate(application.created_at)}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td
                                colSpan="7"
                                className="px-6 py-3 text-center text-sm text-gray-500 font-semibold bg-gray-100 border-t"
                                >
                                No Applications Submitted
                                </td>
                          </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Applications;
