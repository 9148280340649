import { useNavigate } from 'react-router-dom';

const HeroSection = ({ name, description, logo }) => {
    const navigate = useNavigate();

    return (
        <div className="w-full flex items-center justify-center pt-28 md:pt-20 xl:pt-32">
            <div className='w-full md:w-[80%] flex flex-col md:flex-row items-center justify-between'>
                <div className="w-full md:w-[50%] h-full flex justify-center items-center">
                    <img
                        className="w-60 h-auto md:w-72 md:h-68 lg:w-96 lg:h-76"
                        src={logo}
                        alt={name}
                    />
                </div>

                <div className="w-full md:w-[50%] space-y-8 py-4 md:py-12 px-10 md:px-4 lg:px-12 flex flex-col justify-center items-center md:items-start">
                        <h2 className="text-center md:text-left font-bold text-4xl lg:text-5xl">{name}</h2>
                        <p className="text-center md:text-left font-light text-md lg:text-lg xl:text-xl">{description}</p>
                        <button onClick={() => navigate('/contact')} type="button" class="px-12 py-[15px] bg-[#1F2732] rounded-[5rem] font-bold text-lg text-[#0EAC3B] hover:scale-105">
                            Free Demo
                        </button>
                </div>
            </div>
        </div>
    )
}

export default HeroSection;