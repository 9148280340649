import React, { useState, useEffect } from 'react';
import axios from 'axios';

const BlogsPage = () => {
    useEffect (()=>{
        window.scrollTo(0,0);
    })
    
    const [blogs, setBlogs] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await axios.get('http://127.0.0.1:8000/api/blogs');
                setBlogs(response.data);
            } catch (error) {
                console.error('Error fetching blogs:', error);
            }
        };

        fetchBlogs();
    }, []); 

    const filteredBlogs = blogs.filter(blog =>
        blog.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="min-h-screen">
            <section className="bg-gradient-to-r from-[#0C0B22] via-[#0C162D] to-[#0A0919] text-white pt-28 pb-14 px-6">
                <div className="container mx-auto text-center">
                    <h2 className="text-4xl font-bold">Watch out our latest blogs</h2>
                    <p className="mt-2 text-lg">Find the latest news and insights from our blog collection</p>

                    <div className="mt-10 max-w-2xl mx-auto">
                        <input
                            type="text"
                            placeholder="Search blogs..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className="w-full px-4 py-2 rounded-lg border bg-white text-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        />
                    </div>
                </div>
            </section>

            <section className="container mx-auto py-10 px-6">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                    {filteredBlogs.length === 0 ? (
                        <p className="text-center text-lg text-gray-600 col-span-full">No blogs found</p>
                    ) : (
                        filteredBlogs.map((blog) => (
                            <div key={blog.id} className="bg-white rounded-lg shadow-lg overflow-hidden group">
                                <div className="relative overflow-hidden group">
                                    <img
                                        src={blog.image}
                                        alt={blog.title}
                                        className="w-full h-48 object-cover transform transition-all duration-500 group-hover:scale-110"
                                    />
                                </div>
                                <div className="p-6">
                                    <h3 className="text-2xl font-semibold text-gray-800">{blog.title}</h3>
                                    <p className="mt-2 text-sm text-gray-600">{blog.description}</p>
                                    <div className="mt-4">
                                        <button className="text-indigo-600 hover:text-indigo-800 text-sm font-semibold">
                                            Read more
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </section>
        </div>
    );
};

export default BlogsPage;
