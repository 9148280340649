import React, { useState } from 'react';

const Dashboard = () => {
    const [inquiries, setInquiries] = useState([]);

    // useEffect(() => {
    //     const fetchInquiries = async () => {
    //         try {
    //             const data = await getInquiries();
    //             console.log(data.inquiries)
    //             setInquiries(data.inquiries);  
    //         } catch (error) {
    //             console.error('Error fetching inquiries:', error);
    //         }
    //     };
        
    //     fetchInquiries();
    // }, []); 

    const formatDate = (dateString) => {
        const date = new Date(dateString);
    
        return date.toLocaleString('en-US', { 
            year: 'numeric', 
            month: '2-digit', 
            day: '2-digit', 
            hour: '2-digit', 
            minute: '2-digit', 
            second: '2-digit', 
            hour12: true 
        });
    };

    return (
        <div className="flex h-screen">
            <div className="flex-1 p-6">
                <div className="flex justify-between items-center border-b pb-4">
                    <h2 className="text-3xl font-bold">Message Submissions</h2>
                </div>

                <div className="mt-6 overflow-x-auto">
                    <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md">
                        <thead className="bg-gray-100">
                            <tr>
                                <th className="px-6 py-3 text-left text-md font-semibold text-gray-800">Name</th>
                                <th className="px-6 py-3 text-left text-md font-semibold text-gray-800">Email</th>
                                <th className="px-6 py-3 text-left text-md font-semibold text-gray-800">Subject</th>
                                <th className="px-6 py-3 text-left text-md font-semibold text-gray-800">Message</th>
                                <th className="px-6 py-3 text-left text-md font-semibold text-gray-800">Created At</th>
                            </tr>
                        </thead>
                        <tbody>
                            {inquiries.map((inquiry, index) => (
                                <tr key={index} className="border-t">
                                    <td className="px-6 py-3 text-sm text-gray-700">{inquiry.name}</td>
                                    <td className="px-6 py-3 text-sm text-gray-700">{inquiry.email}</td>
                                    <td className="px-6 py-3 text-sm text-gray-700">{inquiry.subject}</td>
                                    <td className="px-6 py-3 text-sm text-gray-700">{inquiry.message}</td>
                                    <td className="px-6 py-3 text-sm text-gray-700">{formatDate(inquiry.created_date)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
