import Carousel from "../SharedComponents/carousel";

const UseCasesSection = ({ use_cases_list1 = [], use_cases_list2 = [] }) => {
    return (
        <div className="bg-white w-screen h-auto px-12 flex-col items-center justify-center">
            <div className="flex justify-center w-full">
                <div className="h-[3rem] xl:h-[4rem] xs:border-l-[2px] border-l-[3px] border-dashed border-black xs:w-[3px] w-[4px]"></div>
            </div>

            <div className="text-black font-customFont font-semibold text-center mb-6 py-8">
                <h1 className="text-4xl">Use Case</h1>
            </div>

            <div className="mt-[1rem]">
                <div>
                    <Carousel autoSlide={true}>
                        {use_cases_list1.map((usecase, index) => (
                            <div key={index} className="w-full flex items-center justify-center">
                                <div className="bg-gradient-to-b from-[#0C0B22] via-[#0C162D] to-[#0A0919] rounded-xl shadow-lg w-full sm:w-[80%] h-56 text-left flex flex-col items-left justify-center px-8 sm:px-12 space-y-4">
                                    <h1 className="font-bold text-[#00FF57] text-xl sm:text-2xl">{usecase.name}</h1>
                                    <p className="text-white text-sm sm:text-md lg:text-xl">{usecase.desc}</p>
                                </div>
                            </div>
                        ))}
                    </Carousel>
                </div>

                <div>
                    <Carousel autoSlide={true}>
                        {use_cases_list2.map((usecase, index) => (
                            <div key={index} className="w-full flex items-center justify-center">
                                <div className="bg-gradient-to-b from-[#0C0B22] via-[#0C162D] to-[#0A0919] rounded-xl shadow-lg w-full sm:w-[80%] h-56 text-left flex flex-col items-left justify-center px-8 sm:px-12 space-y-4">
                                    <h1 className="font-bold text-[#00FF57] text-xl sm:text-2xl">{usecase.name}</h1>
                                    <p className="text-white text-sm sm:text-md lg:text-xl">{usecase.desc}</p>
                                </div>
                            </div>
                        ))}
                    </Carousel>
                </div>
            </div>

            <div className="flex justify-center w-full mt-10">
                <div className="h-[3rem] xl:h-[4rem] xs:border-l-[2px] border-l-[3px] border-dashed border-black xs:w-[3px] w-[4px]"></div>
            </div>
        </div>
    );
};

export default UseCasesSection;
