import React, {useEffect} from "react";
import { images } from "../../assets/images/images";
import ProductCard from "./Cards/ProductCard";
import Carousel from "../SharedComponents/carousel";

const BusinessManagementSolutions = () => {
  useEffect (()=>{
    window.scrollTo(0,0);
})

const products = [
    {
      title: "FINNOVATE",
      image: images.Finnovate,
      content: "Optimize financial workflows and streamline operations with our all-in-one ERP solution.",
      redirectTo: "/products/1"
    },
    {
      title: "SEASYS",
      image: images.Seasys,
      content: "Smart workforce management for maritime companies, streamlining scheduling and operations.",
      redirectTo: "/products/2"
    },
    {
      title: "PLATAEIA",
      image: images.Plateria,
      content: "AI-powered POS for seamless restaurant management and exceptional  customer  experiences.",
      redirectTo: "/products/3"
    },
  ];
  return (
    <section className="w-[100%] h-full xs:h-[100%] mb-10">
      <div className="w-full text-center flex items-center justify-center flex-col pt-[100px]">
        <h1 className="text-4xl font-bold mb-5">OUR PRODUCTS</h1>
        <div className="flex justify-center w-full xl:h-[6rem] mb-5">
          <div className="h-[4rem] lg:h-[4rem] xl:h-[6rem] xs:border-l-[3px] border-l-[4px] border-dashed border-black xs:w-[3px] w-[4px] "></div>
        </div>
        <h1 className="text-4xl font-bold">Business Management Solutions</h1>
      </div>

      <div className="w-full py-5">
        <div className="lg:hidden w-full">
          <Carousel autoSlide={true}>
            {products.map((unit) => (
              <ProductCard
                title={unit.title}
                picture={unit.image}
                content={unit.content}
                bgColor="#001442"
                textColor="#FFFFFF"
                redirectTo= {unit.redirectTo}
              />
            ))}
          </Carousel>
        </div>
        <div className="hidden lg:flex h-[35rem] mb-[1rem] w-full justify-center items-center px-[10%]">
          {products.map((unit) => (
            <ProductCard
              title={unit.title}
              picture={unit.image}
              content={unit.content}
              bgColor="#001442"
              textColor="#FFFFFF"
              redirectTo= {unit.redirectTo}
            />
          ))}
        </div>
      </div>

      <div className="w-full flex items-center justify-center">
        <button className="px-12 py-[15px] bg-[#1F2732] rounded-[5rem] font-bold text-lg text-[#0EAC3B]">
            Free Demo
        </button>
      </div>
    </section>
  );
};
 
export default BusinessManagementSolutions;
