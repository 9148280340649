import { useRef } from "react";
import GetInTouchSection from "../components/Landing Page Components/GetInTouchSection";
import HeroSection from "../components/Landing Page Components/HeroSection";
import RoadMap from "../components/Landing Page Components/RoadMap";
import Services from "../components/Landing Page Components/Services";
import MainLayout from "../components/SharedComponents/MainLayout";

export default function Home() {
  const servicesRef = useRef(null);

  const scrollToServices = () => {
    servicesRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="w-screen">
      <MainLayout>
        <main>
          <HeroSection onMouseIconClick={scrollToServices} />
          <div ref={servicesRef}>
            <Services />
          </div>
          <RoadMap />
          <GetInTouchSection />
        </main>
      </MainLayout>
    </div>
  );
}
