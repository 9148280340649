import Carousel from "../SharedComponents/carousel";
import 'swiper/css';

const Offers = ({ offers = [] }) => {
    return (
        <div className="bg-gradient-to-b from-[#0C0B22] via-[#0C162D] to-[#0A0919] w-screen h-auto py-8 px-12 flex-col items-center justify-center">
            <div className="text-white font-customFont font-semibold text-4xl mb-6 py-8">
                Our Solutions Development Offerings
            </div>

            <div className="mt-[1rem]  ">
                <div className="lg:hidden">
                    <Carousel autoSlide={true}>
                        {offers.map((offer, index) => (
                            <div key={index} className="flex items-center justify-center">
                                <div className="bg-white rounded-lg shadow-lg p-6 w-60 h-60 text-center flex items-center justify-center">
                                    <p className="font-bold text-black">{offer}</p>
                                </div>
                            </div>
                        ))}
                    </Carousel>
                </div>
                <div className="hidden  lg:flex h-[16rem]  mb-[1rem] w-full justify-between items-center px-[10%]">
                    {offers.map((offer, index) => (
                        <div key={index} className="">
                            <div className="bg-white rounded-lg shadow-lg p-6 w-60 h-60 text-lg text-center flex items-center justify-center">
                                <p className="font-bold text-black">{offer}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Offers;
