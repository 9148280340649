import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Applications from "./Applications";
import { create_vacancy, get_vacancy, get_vacancy_categories } from "../../API/CareersAPI";

const Careers = () => {
    const [categories, setCategories] = useState([]);
    const [vacancies, setVacancies] = useState({});
    const [showForm, setShowForm] = useState(false);
    const [useNewCategory, setUseNewCategory] = useState(false);
    const [formData, setFormData] = useState({
      title: "",
      categoryName: "",
      description: "",
    });

    useEffect(() => {
        fetchVacancies();
        fetchCategories();
    }, []);

    const [currentView, setCurrentView] = useState("vacancies");
    const [selectedVacancy, setSelectedVacancy] = useState(null);

    const fetchVacancies = async () => {
      const response = await get_vacancy();
      setVacancies(response.data);
    };

    const fetchCategories = async () => {
      const response = await get_vacancy_categories();
      setCategories(response.data);
    };

    const handleInputChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleCategoryChange = (e) => {
      const selectedValue = e.target.value;
      if (selectedValue === "new") {
        setUseNewCategory(true); 
        setFormData({ ...formData, categoryName: "" });
      } else {
        setUseNewCategory(false); 
        setFormData({ ...formData, categoryName: selectedValue });
      }
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
    
      const formDataObject = new FormData();
      formDataObject.append("title", formData.title);
      formDataObject.append("description", formData.description);
      formDataObject.append("categoryName", formData.categoryName);
    
      try {
        const response = await create_vacancy(formData);
        console.log("response", response.status)

        if (response.status === 200) {
          toast.success("Vacancy created successfully!");
        } else {
          toast.error("Something went wrong!");
        }

        setShowForm(false);
        setUseNewCategory(false);
        setFormData({ title: "", categoryName: "", description: "" });
    
      } catch (error) {
        console.error("Error adding vacancy:", error);
      }
    };

    const handleCancel = async (e) => {
      e.preventDefault();
      setShowForm(false);
      setUseNewCategory(false);
    }

    const handleViewApplications = (vacancy) => {
      setSelectedVacancy(vacancy);
      setCurrentView("applications"); 
    };

    return (
        <div className="min-h-screen bg-gray-50 p-6">
            {currentView === "vacancies" && (
              <div className="container mx-auto bg-white p-8 rounded-lg shadow-md">
                      <h2 className="text-3xl font-semibold text-gray-800 mb-6">Manage vacancies</h2>

                      {!showForm && (
                          <button
                              onClick={() => setShowForm(true)}
                              className="py-2 px-4 bg-[#0C162D] bg-opacity-70 text-white font-semibold rounded-lg hover:bg-[#0C162D] focus:outline-none focus:ring-2 focus:ring-[#0e1933]"
                          >
                              Add New vacancy
                          </button>
                      )}

                  {showForm && currentView === "vacancies" && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                      <div className="bg-white p-6 rounded shadow-lg w-96">
                        <h2 className="text-xl font-semibold mb-4">Add New Vacancy</h2>
                        <form onSubmit={handleSubmit}>
                          <label className="block mb-2">Title:</label>
                          <input
                            type="text"
                            name="title"
                            value={formData.title}
                            onChange={handleInputChange}
                            required
                            className="w-full border p-2 rounded mb-3"
                          />

                          <label className="block mb-2">Category:</label>
                          {!useNewCategory ? (
                            <select
                              name="categoryName"
                              onChange={handleCategoryChange}
                              className="w-full border p-2 rounded mb-3"
                              value={formData.categoryName}
                            >
                              <option value="">Select a category</option>
                              {categories.map((category) => (
                                <option key={category.id} value={category.category_name}>
                                  {category.category_name}
                                </option>
                              ))}
                              <option value="new">+ Add New Category</option>
                            </select>
                          ) : (
                            <input
                              type="text"
                              name="categoryName"
                              value={formData.categoryName}
                              onChange={handleInputChange}
                              placeholder="Enter new category"
                              className="w-full border p-2 rounded mb-3"
                              required
                            />
                          )}

                          <label className="block mb-2 mt-3">Description:</label>
                          <textarea
                            name="description"
                            value={formData.description}
                            onChange={handleInputChange}
                            required
                            className="w-full border p-2 rounded mb-3"
                          ></textarea>

                          <div className="flex justify-end gap-2">
                            <button
                              type="button"
                              onClick={handleCancel}
                              className="bg-gray-400 text-white px-4 py-2 rounded"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="bg-green-600 text-white px-4 py-2 rounded"
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  )}
              </div>
            )}

            {currentView === "vacancies" && (
              <>
                <div className="container mx-auto mt-10 px-4">
                    {categories.length > 0 ? (
                        <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-6">
                            {categories.map((category) => (
                                <div key={category.id} className="bg-white shadow-lg px-4 py-2 border-2 border-solid border-gray-800 rounded-full hover:bg-gray-800 transition flex flex-col items-center justify-center cursor-pointer text-gray-800 hover:text-white">
                                    <h3 className="text-lg font-bold">{category.category_name}</h3>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p className="text-gray-600">No vacancies available</p>
                    )}
                </div>

                <div className="container mx-auto mt-10 px-4">
                    
                    {vacancies.length > 0 ? (
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                            {vacancies.map((vacancy) => (
                                <div key={vacancy.id} className="bg-white shadow-lg rounded-lg p-4 hover:shadow-xl transition flex flex-col items-center justify-center cursor-pointer">
                                    <h3 className="text-lg font-bold text-gray-800">{vacancy.title}</h3>
                                    <h3 className="text-blue-400 font-semibold" onClick={() => handleViewApplications(vacancy)}>View Applications</h3>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p className="text-gray-600">No vacancies available</p>
                    )}
                </div>
              </>
            )}

            {currentView === "applications" && selectedVacancy && (
              <Applications vacancy={selectedVacancy} />
            )}
        </div>
    );
};

export default Careers;
