import { useState } from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <header className="fixed z-10 w-screen top-0 bg-gradient-to-r from-[#3e3e3e] to-[#666666] opacity-90 sm:flex sm:justify-between sm:items-center sm:px-4 sm:py-3">
      <div className="flex items-center justify-between px-4 py-3 sm:p-0">
        <div className="sm:hidden">
          <button
            onClick={() => setIsOpen(!isOpen)}
            type="button"
            className="block text-white hover:text-gray-300 focus:text-gray-300 focus:outline-none"
          >
            <svg
              className="h-6 w-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              {isOpen ? (
                <path
                  fillRule="evenodd"
                  d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                />
              ) : (
                <path
                  fillRule="evenodd"
                  d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                />
              )}
            </svg>
          </button>
        </div>
      </div>
      

      <nav
        className={`${
          isOpen ? "block" : "hidden"
        } sm:flex sm:justify-center sm:items-center sm:p-0 w-full`}
      >
        <div className="flex items-center justify-center bg-white rounded-full shadow-md w-[160px] h-[40px]">
          <Link to="/">
            <img
              className="justify-center items-center p-8"
              src="/logo.png"
              alt="Enigma Solutions"
            />
          </Link>
        </div>
        <Link
          to="/services"
          className="mt-1 block px-4 py-2 text-white font-semibold rounded hover:bg-gray-800 sm:mt-0 sm:ml-4"
        >
          Services
        </Link>
        <Link
          to="/products"
          className="mt-1 block px-4 py-2 text-white font-semibold rounded hover:bg-gray-800 sm:mt-0 sm:ml-4"
        >
          Products
        </Link>
        <Link
          to="/about"
          className="mt-1 block px-4 py-2 text-white font-semibold rounded hover:bg-gray-800 sm:mt-0 sm:ml-4"
        >
          About Us
        </Link>
        <Link
          to="/career"
          className="block px-4 py-2 text-white font-semibold rounded hover:bg-gray-800"
        >
          Career
        </Link>
        <Link
          to="/blogs"
          className="mt-1 block px-4 py-2 text-white font-semibold rounded hover:bg-gray-800 sm:mt-0 sm:ml-4"
        >
          Blog
        </Link>
        <Link
          to="/contact"
          className="mt-1 block px-4 py-2 text-white font-semibold rounded hover:bg-gray-800 sm:mt-0 sm:ml-4"
        >
          Contact Us
        </Link>
      </nav>
    </header>
  );
};

export default Navbar;
