import React, {useEffect} from "react";
import { images } from "../../assets/images/images";
import ProductCard from "./Cards/ProductCard";
import Carousel from "../SharedComponents/carousel";

const CustomerEngagement = () => {
  useEffect (()=>{
    window.scrollTo(0,0);
})

const products = [
    {
      title: "FINNOVESTA",
      image: images.Finnovesta,
      content: "Analytics-driven CRM for managing investors and client relations in investment banking",
      redirectTo: "/products/4"
    },
    {
      title: "REWARDHUB",
      image: images.RewardHub,
      content:
        "Boost customer loyalty with customizable rewards and retention tools",
        redirectTo: "/products/5"
    },
  ];
  return (
    <section className="w-[100%] h-full xs:h-[100%] mb-10 bg-gradient-to-r from-[#0A0919] via-[#0D1830] to-[#0A0919]">
        <div className="flex justify-center w-full xl:h-[6rem] mb-5">
          <div className="h-[4rem] lg:h-[4rem] xl:h-[6rem] xs:border-l-[3px] border-l-[4px] border-dashed border-white xs:w-[3px] w-[4px]"></div>
        </div>

        <div className="w-full text-center flex items-center justify-center flex-col">
            <h1 className="text-4xl text-white font-bold">Customer Engagement & Growth Tools</h1>
        </div>

        <div className="w-full py-5">
            <div className="lg:hidden w-full">
            <Carousel autoSlide={true}>
                {products.map((unit) => (
                <ProductCard
                    title={unit.title}
                    picture={unit.image}
                    content={unit.content}
                    bgColor="#E4E0E0" 
                    textColor="#001442"
                    redirectTo= {unit.redirectTo}
                />
                ))}
            </Carousel>
            </div>
            <div className="hidden lg:flex h-[35rem] mb-[1rem] w-full justify-center items-center px-[10%]">
            {products.map((unit) => (
                <ProductCard
                title={unit.title}
                picture={unit.image}
                content={unit.content}
                bgColor="#E4E0E0" 
                textColor="#001442"
                redirectTo= {unit.redirectTo}
                />
            ))}
            </div>
        </div>

        <div className="w-full flex items-center justify-center mb-10">
            <button className="px-12 py-[15px] bg-white rounded-[5rem] font-bold text-lg text-[#0EAC3B]">
                Free Demo
            </button>
        </div>

        <div className="flex justify-center w-full xl:h-[6rem]">
          <div className="h-[4rem] lg:h-[4rem] xl:h-[6rem] xs:border-l-[3px] border-l-[4px] border-dashed border-white xs:w-[3px] w-[4px]"></div>
        </div>
    </section>
  );
};
 
export default CustomerEngagement;
