import React, {useEffect} from "react";
import { images } from "../../assets/images/images";
import ProductCard from "./Cards/ProductCard";
import Carousel from "../SharedComponents/carousel";

const SpecializedPlatforms = () => {
  useEffect (()=>{
    window.scrollTo(0,0);
})

const products = [
    {
      title: "EFFIINITI",
      image: images.Effiniti,
      content: "Simplify sales and task management with a focused funnel, project and tasks management solution",
      redirectTo: "/products/6"
    },
    {
      title: "BOOKOVA",
      image: images.Bookova,
      content: "Hassle-free rental bookings for travel, appointments, vehicles, and more",
      redirectTo: "/products/7"
    },
    {
      title: "EDURA",
      image: images.Edura,
      content: "Advanced LMS for personalized learning and workforce training",
      redirectTo: "/products/8"
    },
  ];
  return (
    <section className="w-[100%] h-full xs:h-[100%] py-[50px]">
      <div className="w-full text-center flex items-center justify-center flex-col">
        <h1 className="text-4xl font-bold">Specialized Platforms</h1>
      </div>

      <div className="w-full py-5">
        <div className="lg:hidden w-full">
          <Carousel autoSlide={true}>
            {products.map((unit) => (
              <ProductCard
                title={unit.title}
                picture={unit.image}
                content={unit.content}
                bgColor="#001442"
                textColor="#FFFFFF"
                redirectTo= {unit.redirectTo}
              />
            ))}
          </Carousel>
        </div>
        <div className="hidden lg:flex h-[35rem] mb-[1rem] w-full justify-center items-center px-[10%]">
          {products.map((unit) => (
            <ProductCard
              title={unit.title}
              picture={unit.image}
              content={unit.content}
              bgColor="#001442" 
              textColor="#FFFFFF"
              redirectTo= {unit.redirectTo}
            />
          ))}
        </div>
      </div>

      <div className="w-full flex items-center justify-center">
        <button className="px-12 py-[15px] bg-[#1F2732] rounded-[5rem] font-bold text-lg text-[#0EAC3B]">
            Free Demo
        </button>
      </div>
    </section>
  );
};
 
export default SpecializedPlatforms;
