import MainLayout from "../components/SharedComponents/MainLayout";
import BusinessManagementSolutions from "../components/ProductsPageComponents/BusinessManagementSolutions";
import CustomerEngagement from "../components/ProductsPageComponents/CustomerEngagement";
import SpecializedPlatforms from "../components/ProductsPageComponents/SpecializedPlatforms";

export default function AboutUs() {
  return (
    <MainLayout>
      <main>
        <BusinessManagementSolutions />
        <CustomerEngagement />
        <SpecializedPlatforms />
      </main>
    </MainLayout>
  );
}
