import React, {useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { images } from '../assets/images/images';
import MainLayout from '../components/SharedComponents/MainLayout';
import HeroSection from '../components/IndividualProductPageComponents/HeroSection';
import SegmentSection from '../components/IndividualProductPageComponents/SegmentsSection';
import FeaturesSection from '../components/IndividualProductPageComponents/FeaturesSection';
import UseCasesSection from '../components/IndividualProductPageComponents/UseCasesSection';
import OverviewSection from '../components/IndividualProductPageComponents/OverviewSection';
import BenefitsSection from '../components/IndividualProductPageComponents/BenefitsSection';

const ProductPage = () => {
    const { productId } = useParams();

    useEffect (()=>{
        window.scrollTo(0,0);
    })

    const productsData = {
        '1': {
            name: 'FINNOVATE',
            description: 'Finnovate ERP is a comprehensive, intuitive platform designed to optimize financial operations, streamline workflows, and enhance business performance across industries. With advanced features and seamless integrations, Finnovate empowers organizations to manage finances, operations, and customer relations efficiently—all from a unified interface.',
            logo: images.Finnovate,
            mdoule_image: images.FinnovateDashboard,
            segments: [
                {
                    name: 'Small and Medium Enterprises (SMEs)',
                    desc: 'Manage operations and customer relations seamlessly.',
                },
                {
                    name: 'Large Enterprises',
                    desc: 'Handle complex processes and cross-department collaborations.',
                },
                {
                    name: 'Industry-specific Solutions',
                    desc: 'Tailored modules for manufacturing, retail, and HR management.',
                }
            ],
            features: [
                {
                    name: 'Comprehensive Financial Management',
                    desc: 'Track transactions, manage budgets, and monitor cash flows. Simplify financial operations with real-time updates and insights.',
                },
                {
                    name: 'Customer Management',
                    desc: 'Store and manage customer details, invoices, and credit notes. Enhance customer relationships with centralized and accessible data.',
                },
                {
                    name: 'Integrated Sales Tools',
                    desc: 'Streamline proposals, estimates, invoices, and payments. Track and optimize the entire sales process with ease.',
                },
                {
                    name: 'Inventory and Supply Chain Oversight',
                    desc: 'Monitor stock levels, manage warehouses, and track goods movement. Ensure seamless inventory control with real-time synchronization.',
                },
                {
                    name: 'HR and Payroll Automation',
                    desc: 'Manage staff records, payroll, attendance, and timesheets. Automate HR processes to save time and boost productivity.',
                },
                {
                    name: 'Advanced Reporting and Dashboards',
                    desc: 'Access detailed reports on operations, finances, and sales. Leverage real-time analytics for data-driven decision-making.',
                }
            ],
            use_cases_list1: [
                {
                    name: 'Customer Relationship Management',
                    desc: 'Finnovate provides a centralized system for managing customer profiles, invoices, and credit notes. This helps businesses streamline interactions, improve satisfaction, and build lasting relationships.',
                },
                {
                    name: 'End-to-End Financial Oversight',
                    desc: 'Finnovate enables complete control over financial operations, from transactions and budgeting to reconciliation. It centralizes all financial details for improved accuracy and compliance.',
                },
                {
                    name: 'Task and Workflow Monitoring',
                    desc: 'With its task and activity tracking capabilities, Finnovate ensures that teams can monitor deadlines, align workflows, and maintain operational efficiency.',
                },
            ],
            use_cases_list2: [
                {
                    name: 'Sales Pipeline Optimization',
                    desc: 'Finnovate helps businesses manage deals, proposals, invoices, and payments effectively. By organizing the sales pipeline, it improves transparency and accelerates deal closures.',
                },
                {
                    name: 'Inventory and Supply Chain Management',
                    desc: 'Finnovate simplifies inventory management by tracking stock, warehouses, and goods movement. It ensures smooth supply chain operations with real-time updates.',
                },
                {
                    name: 'Comprehensive Reporting and Insights',
                    desc: 'Finnovate provides advanced reporting and analytics across finances, sales, and operations, enabling businesses to make data-driven decisions with confidence.',
                },
            ],
            benefits: [
                {
                    name: 'Enhanced Efficiency',
                    desc: 'Automate core processes like finance, purchase, and HR, reducing manual effort and ensuring accuracy across departments.',
                },
                {
                    name: 'Optimized Resource Utilization',
                    desc: 'Effectively manage inventory, workforce, and project timelines to maximize productivity.',
                },
                {
                    name: 'Costs Saving',
                    desc: 'Eliminate redundancies, streamline procurement, and minimize manual errors to reduce operational expenses.',
                },
                {
                    name: 'Actionable Insights',
                    desc: 'Leverage robust analytics and customizable reports to make data-driven decisions for business growth.',
                },
                {
                    name: 'Improved Financial Oversight',
                    desc: 'Gain real-time visibility into budgets, expenses, and income for better financial planning and control.',
                },
                {
                    name: 'Scalability and Flexibility',
                    desc: 'Adapt Finnovate to fit your unique workflows and scale effortlessly as your business expands.',
                }
            ]
        },
        '2': {
            name: 'SEASYS',
            description: 'Seasys is an innovative maritime management platform developed to streamline operations for seafarer outsourcing companies and ship management professionals. It consolidates critical maritime processes, enhances workforce productivity, and ensures compliance with industry standards. From seafarer profiling to vessel allocation and comprehensive reporting, Seasys is designed to simplify complex workflows and drive efficiency in maritime management.',
            logo: images.Seasys,
            mdoule_image: images.SeasysDashboard,
            segments: [
                {
                    name: 'Seafarer Management',
                    desc: 'Comprehensive profiles, certifications, and assignments for efficient workforce handling.',
                },
                {
                    name: 'Vessel and Principal Management',
                    desc: 'Centralized details for vessels and associated principals to simplify operations.',
                }
            ],
            features: [
                {
                    name: 'Comprehensive Seafarer Management',
                    desc: 'Detailed profiles with essential seafarer information, including certifications, service history, and endorsements. Centralized data for planning, briefing, and debriefing processes.',
                },
                {
                    name: 'Vessel and Principal Oversight',
                    desc: 'Manage vessel details, including type, specifications, and registration data. Comprehensive principal records with key information for effective collaboration.',
                },
                {
                    name: 'Streamlined Seafarer Assignments',
                    desc: 'Assign crew with detailed salary structures, contract durations, and joining/sign-off details. Real-time crew allocation summaries for enhanced decision-making.',
                },
                {
                    name: 'Dynamic Rates and Charges Management',
                    desc: 'Create customized rate cards for vessels, ranks, and principals. Manage cost items with detailed tracking for currencies and quantities.',
                },
                {
                    name: 'Invoicing and Financial Automation',
                    desc: 'Generate invoices with references and automated tracking. Resend and view invoice summaries for streamlined financial processes.',
                },
                {
                    name: 'Comprehensive Reporting and Insights',
                    desc: 'Generate actionable reports for onboarding, leave management, and briefing/debriefing. Export reports as Excel files for easy sharing and analysis.',
                },
            ],
            use_cases_list1: [
                {
                    name: 'Centralized Seafarer Data Management',
                    desc: 'Seasys consolidates seafarer details, including certifications, service history, and endorsements, into a single repository. This enables quick access to essential information, ensuring smooth operations and compliance.',
                },
                {
                    name: 'Streamlined Briefing Processes',
                    desc: 'Seasys facilitates structured briefing and debriefing processes. Teams can efficiently share updates, capture notes, and maintain clear communication before and after voyages.',
                },
                {
                    name: 'Automated Invoicing and Financial Tracking',
                    desc: 'The platform streamlines invoicing with automated workflows, reducing errors and delays. Detailed financial tracking ensures smooth communication with principals and accurate billing.',
                },
            ],
            use_cases_list2: [
                {
                    name: 'Efficient Crew Allocation and Planning',
                    desc: 'The platform simplifies crew assignments by matching seafarers with vessel requirements, contract durations, and rank suitability. This ensures optimal resource utilization and streamlined planning.',
                },
                {
                    name: 'Dynamic Rate Card Creation',
                    desc: 'Seasys offers customizable rate cards tailored to vessels, ranks, and principals. This flexibility supports transparent financial negotiations and simplifies cost management.',
                },
                {
                    name: 'Insightful Reporting and Analytics',
                    desc: 'Seasys provides actionable reports on onboarding, leave, and assignments. Exportable data enables maritime professionals to make informed decisions and optimize operations effectively.',
                },
            ],
            benefits: [
                {
                    name: 'Enhanced Efficiency',
                    desc: 'Simplify seafarer management with centralized profiles, assignments, and planning tools, reducing time spent on manual tasks.',
                },
                {
                    name: 'Seamless Communication',
                    desc: 'Enhance collaboration between principals, vessels, and seafarers through real-time updates and accessible data.',
                },
                {
                    name: 'Costs Saving',
                    desc: 'Automate charge calculations, invoicing, and reporting to minimize errors and lower administrative expenses.',
                },
                {
                    name: 'Scalability',
                    desc: 'Adjust the platform as your maritime operations expand, ensuring sustained growth and ROI.',
                },
                {
                    name: 'Improved Accuracy',
                    desc: 'Maintain precise records with integrated settings and modules, ensuring consistency across all operations.',
                },
                {
                    name: 'Informed Decisions',
                    desc: 'Leverage detailed reports and analytics to track performance and make strategic choices.',
                },
            ]
        },
        '3': {
            name: 'PLATAEIA',
            description: 'Plateria is an AI-powered POS solution designed to revolutionize restaurant management by optimizing operations, enhancing efficiency, and delivering exceptional customer experiences. With its intelligent features and seamless integrations, Plateria enables restaurants to manage orders, reservations, inventory, and customer interactions—all from a single, intuitive platform.',
            logo: images.Plateria,
            mdoule_image: images.PlateriaDashboard,
            segments: [
                {
                    name: 'Restaurants & Cafés',
                    desc: 'Streamline ordering, kitchen management, and customer service.',
                },
                {
                    name: 'Quick-Service & Cloud Kitchens',
                    desc: 'Enhance speed, accuracy, and order tracking.',
                },
                {
                    name: 'Franchises & Multi-Location Chains',
                    desc: 'Centralized control with scalable management tools.',
                }
            ],
            features: [
                {
                    name: 'AI-Powered POS System',
                    desc: 'Streamline order processing with an intuitive POS interface. Automate transactions and enhance accuracy with AI-driven insights.',
                },
                {
                    name: 'HR & Payroll Management',
                    desc: 'Handle employee attendance, payroll, and leave tracking. Automate recruitment, performance tracking, and expenses.',
                },
                {
                    name: 'Detailed Reports & Analytics',
                    desc: 'Generate sales, stock, and cash register reports for better decision-making. Filter sales by date, table, or commission to track performance.',
                },
                {
                    name: 'Order & Kitchen Management',
                    desc: 'Manage dine-in, takeout, and online orders efficiently. Sync kitchen and counter dashboards for real-time updates.',
                },
                {
                    name: 'Comprehensive Inventory & Purchase Management',
                    desc: 'Track stock levels and ingredient usage to prevent shortages. Automate supplier management and purchase returns.',
                },
                {
                    name: 'Reservation & Booking System',
                    desc: 'Allow customers to book tables in advance. Set unavailable days and manage reservations with ease.',
                },
            ],
            use_cases_list1: [
                {
                    name: 'Seamless Order Management',
                    desc: 'Plateria enables restaurants to manage dine-in, takeout, and delivery orders in a single system. The POS dashboard ensures smooth order processing and reduces manual errors.',
                },
                {
                    name: 'Optimized Inventory Control',
                    desc: 'Restaurants can track ingredient usage and automatically reorder stock when levels run low, preventing shortages and ensuring seamless kitchen operations.',
                },
                {
                    name: 'Enhanced Customer Engagement',
                    desc: 'With integrated reservation management and customer feedback tools, Plateria helps businesses improve guest experiences and loyalty.',
                },
            ],
            use_cases_list2: [
                {
                    name: 'Efficient Kitchen Coordination',
                    desc: 'With a dedicated kitchen dashboard, chefs receive real-time order updates, minimizing delays and enhancing food preparation efficiency.',
                },
                {
                    name: 'Automated Payroll & HR Management',
                    desc: 'Plateria simplifies employee management by automating attendance tracking, payroll calculations, and leave approvals, reducing administrative workload.',
                },
                {
                    name: 'Data-Driven Decision Making',
                    desc: 'Detailed reports on sales, expenses, and inventory help restaurant owners analyze performance, optimize pricing strategies, and reduce costs.',
                },
            ],
            benefits: [
                {
                    name: 'Enhanced Operational Efficiency',
                    desc: 'Streamline workflows by managing orders, reservations, inventory, and HR in one platform.',
                },
                {
                    name: 'Customizable User Experience',
                    desc: 'Tailor settings, menus, and access roles for a seamless experience across your business.',
                },
                {
                    name: 'AI - Driven Insights',
                    desc: 'Use AI for smarter decision- making, including order tracking and personalized engagement.',
                },
                {
                    name: 'Reporting & Analytics',
                    desc: 'Generate reports for actionable insights to optimize performance and profitability.',
                },
                {
                    name: 'Real-Time Data Integration',
                    desc: 'Access real-time data for quicker decisions and better customer responsiveness.',
                },
                {
                    name: 'Scalability for Growth',
                    desc: 'Easily scale to multiple locations with centralized management of orders and inventory.',
                },
            ]
        },
        '4': {
            name: 'FINNOVESTA',
            description: 'Finnovesta CRM is a sophisticated, user-centric platform designed specifically for investment banking professionals. It streamlines client and investor management, optimizes project workflows, and provides actionable insights through robust reporting. With advanced modules and seamless task management, Finnovesta ensures efficiency and growth in investment banking operations.',
            logo: images.Finnovesta,
            mdoule_image: images.FinnovestaDashboard,
            segments: [
                {
                    name: 'Client and Investor Management',
                    desc: 'Centralized profiles with detailed views for effective relationship building.',
                },
                {
                    name: 'Workspace for Deals and Leads',
                    desc: 'Intuitive task boards to track progress and milestones.',
                },
                {
                    name: 'Sales and Contracts Management',
                    desc: 'Automated invoicing, proposals, and contract workflows.',
                }
            ],
            features: [
                {
                    name: 'Comprehensive Client & Investor Management',
                    desc: 'Detailed profiles for clients, investors, and leads. Centralized data for projects, files, and tickets. Strengthen relationships with accessible insights.',
                },
                {
                    name: 'Integrated Task Management',
                    desc: 'Organize tasks with drag-and-drop panels. Set due dates, priorities, and dependencies. Assign recurring tasks and track in real-time.',
                },
                {
                    name: 'Knowledge Base & Support Tools',
                    desc: 'Centralized repository for resources. Ticketing system for client queries.',
                },
                {
                    name: 'Sales & Contract Automation',
                    desc: 'Generate proposals, estimates, and invoices. Manage contracts with tailored templates. Automate subscriptions and payments.',
                },
                {
                    name: 'Deal and Lead Tracking',
                    desc: 'Track deals by group type (Buy, Sell, Other Side). Milestones, tasks, and risk mitigation for leads. Convert leads into clients or investors.',
                },
                {
                    name: 'Advanced Reporting and Dashboards',
                    desc: 'Comprehensive reports on deals and sales. Dashboard insights for better decisions.',
                },
            ],
            use_cases_list1: [
                {
                    name: 'Client & Investor Relationship Management',
                    desc: 'Finnovesta centralizes client and investor details, including industry, project status, and contact history. It also enables teams to store and access notes, files, and communication logs in one place, improving relationship management.',
                },
                {
                    name: 'Dynamic Workspace for Projects',
                    desc: 'The platform provides a workspace for managing complex projects, with tools to assign resources, track dependencies, and ensure accountability. Teams can collaborate seamlessly while maintaining focus on deliverables.',
                },
                {
                    name: 'Deal and Lead Tracking',
                    desc: "Finnovesta's detailed deal and lead tracking capabilities include risk assessments, task management, and milestone tracking. It enables teams to anticipate challenges and optimize lead conversion rates effectively.",
                },
            ],
            use_cases_list2: [
                {
                    name: 'Integrated Communication Tools',
                    desc: 'Finnovesta includes built-in messaging to ensure real-time communication between users. This eliminates the need for external platforms, streamlining internal discussions and collaboration.',
                },
                {
                    name: 'Resource Allocation & Management',
                    desc: 'With advanced tools for resource planning, Finnovesta ensures optimal utilization of team members and assets across various projects. It supports dynamic allocation based on project demands and user availability.',
                },
                {
                    name: 'Enhanced Compliance and Documentation',
                    desc: "The platform includes features for managing contracts, legal templates, and documentation. This ensures adherence to industry regulations and reduces administrative overhead.",
                },
            ],
            benefits: [
                {
                    name: 'Enhanced Client Relationship Management',
                    desc: 'Streamline interactions with centralized client and investor profiles, saving time and improving customer satisfaction.',
                },
                {
                    name: 'Accelerated Deal Closures',
                    desc: 'Optimize sales pipelines and manage deals efficiently, reducing delays and driving faster results.',
                },
                {
                    name: 'Improved Team Productivity',
                    desc: 'Leverage task prioritization and workflow management tools to align teams and minimize bottlenecks.',
                },
                {
                    name: 'Data-Driven Decision Making',
                    desc: 'Access advanced analytics and real-time insights to make informed business decisions with confidence.',
                },
                {
                    name: 'Simplified Financial Processes',
                    desc: 'Automate proposals, invoices, and payment tracking, reducing errors and ensuring seamless operations.',
                },
                {
                    name: 'Scalable and Customizable Solutions',
                    desc: 'Adaptable platform that grows with your business, offering tailored configurations for unique requirements.',
                },
            ]
        },
        '5': {
            name: 'REWARDHUB',
            description: 'RewardHub is a powerful, user-friendly loyalty points management system designed to help businesses enhance customer engagement, retain loyal customers, and drive repeat sales. With an intuitive interface and robust administrative controls, RewardHub simplifies the process of managing loyalty programs for businesses of all sizes.',
            logo: images.RewardHub,
            mdoule_image: images.RewardHubDashboard,
            segments: [
                {
                    name: 'Retail & E-commerce',
                    desc: 'Reward frequent shoppers with points and exclusive perks.',
                },
                {
                    name: 'Hospitality & Food Services',
                    desc: 'Engage customers with rewards for repeat visits and referrals.',
                },
                {
                    name: 'Corporate & Membership Programs',
                    desc: 'Manage employee incentives and membership-based loyalty programs seamlessly.',
                }
            ],
            features: [
                {
                    name: 'Multi-Tier Loyalty Programs',
                    desc: 'Design tiered reward structures to incentivize higher spending. Customize reward levels based on business needs.',
                },
                {
                    name: 'Real-Time Points Tracking',
                    desc: 'Monitor and update customer loyalty points instantly. Enable easy redemption for a seamless experience.',
                },
                {
                    name: 'Automated Reward Issuance',
                    desc: 'Configure automatic point allocation based on predefined rules. Reduce manual work and ensure accuracy in reward distribution.',
                },
                {
                    name: 'Partner & Network Management',
                    desc: 'Manage multiple business partners within a loyalty network. Assign specific permissions and oversee performance.',
                },
                {
                    name: 'Customizable Branding',
                    desc: 'Personalize the loyalty program with brand elements. Ensure a consistent customer experience across all touchpoints.',
                },
                {
                    name: 'Detailed Analytics & Reporting',
                    desc: 'Gain valuable insights into customer behavior and engagement. Generate reports on reward redemption rates and program success.',
                },
            ],
            use_cases_list1: [
                {
                    name: 'Retail Chain Loyalty Program',
                    desc: 'Encourage repeat purchases across multiple store locations with a unified points system.',
                },
                {
                    name: 'Corporate Employee Incentives',
                    desc: 'Reward employees with performance-based points redeemable for perks or bonuses.',
                },
                {
                    name: 'Travel & Hospitality Memberships',
                    desc: 'Grant exclusive perks, priority services, and discounts to loyal travelers and hotel guests.',
                },
            ],
            use_cases_list2: [
                {
                    name: 'Restaurant Reward System',
                    desc: 'Offer discounts and free meals based on accumulated points to enhance customer retention.',
                },
                {
                    name: 'E-commerce Cashback Program',
                    desc: 'Provide cashback rewards for online shoppers who make frequent transactions.',
                },
                {
                    name: 'Subscription-Based Discounts',
                    desc: 'Offer ongoing loyalty rewards to subscribers, such as exclusive deals, upgrades, and early access to new features.',
                },
            ],
            benefits: [
                {
                    name: 'Increased Customer Retention',
                    desc: 'Build lasting relationships by offering personalized loyalty rewards, encouraging repeat business and customer loyalty.',
                },
                {
                    name: 'Real-Time Insights',
                    desc: 'Access actionable data and analytics on customer behavior, program effectiveness, and trends, enabling data-driven decisions to optimize marketing efforts.',
                },
                {
                    name: 'Higher Revenue Generation',
                    desc: 'Drive repeat purchases and referrals with a tailored rewards program, boosting sales and increasing customer lifetime value.',
                },
                {
                    name: 'Streamlined Operations',
                    desc: 'Automate loyalty program management, reducing manual tracking and administrative tasks, allowing staff to focus on more strategic business areas.',
                },
                {
                    name: 'Improved Customer Engagement',
                    desc: 'Enhance customer interaction through omni-channel rewards, keeping customers engaged and satisfied across various platforms.',
                },
                {
                    name: 'Seamless Integration',
                    desc: 'Integrate effortlessly with existing systems, including CRM and POS, to ensure smooth operations and minimize disruption.',
                },
            ]
        },
        '6': {
            name: 'EFFIINITI',
            description: 'Effiniti is a powerful, intuitive CRM platform designed to streamline project management, optimize sales pipelines, and enhance collaboration across teams. With robust features and seamless integrations, Effiniti empowers businesses to manage their customers, projects, and operations efficiently, all from a single interface.',
            logo: images.Effiniti,
            mdoule_image: images.EffinitiDashboard,
            segments: [
                {
                    name: 'Small and Medium Enterprises (SMEs)',
                    desc: 'Manage operations and customer relations seamlessly.',
                },
                {
                    name: 'Large Enterprises',
                    desc: 'Handle complex projects and cross-department collaboration.',
                },
                {
                    name: 'Project-Oriented Teams',
                    desc: 'Optimize workflows and improve efficiency.',
                }
            ],
            features: [
                {
                    name: 'Comprehensive Project Management',
                    desc: 'Track progress, assign tasks, and monitor activities. Manage multiple projects effortlessly with real-time updates.',
                },
                {
                    name: 'Customer Relationship Management(CRM)',
                    desc: 'Store and manage customer profiles, business details, and statuses. Build stronger customer relationships with centralized information.',
                },
                {
                    name: 'Integrated Communication Tools',
                    desc: 'Streamline messaging, meetings, and support tickets.',
                },
                {
                    name: 'Task and Activity Oversight',
                    desc: 'Monitor individual and team tasks within projects. Keep activities aligned with deadlines and objectives.',
                },
                {
                    name: 'Sales Pipeline Management',
                    desc: 'Organize and track deals, proposals, invoices, payments, and subscriptions. Increase conversion rates with optimized lead tracking.',
                },
                {
                    name: 'Advanced Reporting and Analytics',
                    desc: 'Gain insights into performance, trends, and key metrics to make data-driven decisions.',
                },
            ],
            use_cases_list1: [
                {
                    name: 'Customer Relationship Management',
                    desc: 'Effiniti provides a centralized system for managing customer profiles, contact details, and business relationships. This helps businesses maintain active customer engagement and build lasting relationships.',
                },
                {
                    name: 'End-to-End Project Oversight',
                    desc: 'Effiniti enables complete control over project lifecycles, from planning and execution to monitoring progress. It keeps all project details, tasks, and team collaborations in one place for enhanced productivity.',
                },
                {
                    name: 'Task and Activity Progress Monitoring',
                    desc: 'With task and activity tracking modules, teams can monitor deadlines, track progress, and ensure that individual and group objectives align with overall project goals.',
                },
            ],
            use_cases_list2: [
                {
                    name: 'Sales Pipeline Optimization',
                    desc: 'Effiniti supports businesses in managing their deals, proposals, and contracts. By keeping sales pipelines organized and transparent, it helps close deals faster and improves conversion rates.',
                },
                {
                    name: 'Contract and Proposal Lifecycle Management',
                    desc: 'Effiniti simplifies the management of proposals and contracts by offering templates, tracking active agreements, and ensuring compliance, reducing the administrative burden.',
                },
                {
                    name: 'Streamlined Communication',
                    desc: 'Through its messaging, meeting, and ticketing features, Effiniti ensures seamless internal and external communication, keeping all stakeholders aligned and informed.',
                },
            ],
            benefits: [
                {
                    name: 'Enhanced Efficiency',
                    desc: 'Streamline workflows with centralized task and project management, saving time and reducing redundancies.',
                },
                {
                    name: 'Improved Collaboration',
                    desc: 'Foster seamless team coordination and customer engagement on one platform',
                },
                {
                    name: 'Costs Saving',
                    desc: 'Automate processes, minimize manual errors, and cut operational costs.',
                },
                {
                    name: 'Scalability',
                    desc: 'Adapt the platform to grow with your business needs, ensuring long-term value.',
                },
                {
                    name: 'Data-Driven Decisions',
                    desc: 'Gain actionable insights with advanced reporting and analytics tools.',
                },
                {
                    name: 'Boosted Revenue',
                    desc: 'Drive productivity and optimize resource allocation to increase profitability.',
                },
            ]
        },
        '7': {
            name: 'BOOKOVA',
            description: 'Bookova is a comprehensive, user-centric booking management platform designed to simplify and optimize rental and appointment-based businesses. It integrates tools for managing bookings, customers, payments, and analytics, all within a single platform. With advanced features and real-time reporting, Bookova empowers businesses to enhance efficiency, customer experience, and revenue generation.',
            logo: images.Bookova,
            mdoule_image: images.BookovaDashboard,
            segments: [
                {
                    name: 'Versatile Booking Management',
                    desc: 'Seamlessly handle various booking types, from appointments and rentals to event reservations.',
                },
                {
                    name: 'Integrated Commerce & Customer Management',
                    desc: 'Manage products, payments, and customer relationships with built-in WooCommerce support.',
                },
                {
                    name: 'Analytics, SEO & Promotions',
                    desc: 'Gain valuable insights into booking trends, customer behavior, and revenue while leveraging SEO and promotional tools.',
                }
            ],
            features: [
                {
                    name: 'Comprehensive Booking Management',
                    desc: 'Handle multiple booking types, with real-time availability tracking. Centralized dashboard for managing all reservations efficiently.',
                },
                {
                    name: 'Integrated Payment Processing',
                    desc: 'Accept secure online payments with multiple gateway options. Automated invoicing and transaction tracking for hassle-free financial management.',
                },
                {
                    name: 'Advanced Analytics & Reporting',
                    desc: 'Gain insights into revenue, customer trends, and booking performance. Customizable reports to make data-driven business decisions.',
                },
                {
                    name: 'SEO & Marketing Automation',
                    desc: 'Built-in tools to improve online visibility and attract more bookings. Automated promotions, discount management, and customer engagement features.',
                },
                {
                    name: 'Customizable and Scalable Platform',
                    desc: 'Modular structure allows businesses to tailor features to their needs. Scalable to support businesses of any size, from small agencies to large enterprises.',
                },
                {
                    name: 'Enhanced Security & Compliance',
                    desc: 'Robust security features to protect user data and transactions. Compliance with industry standards for safe and reliable operations.',
                },
            ],
            use_cases_list1: [
                {
                    name: 'Optimized Appointment Scheduling',
                    desc: 'Bookova simplifies appointment management for businesses like salons, medical clinics, and consultation services, reducing scheduling conflicts and improving client satisfaction.',
                },
                {
                    name: 'Event & Venue Booking',
                    desc: 'Businesses hosting events, conferences, or workspace rentals can automate the entire reservation process, ensuring smooth operations and better customer engagement.',
                },
                {
                    name: 'E-Commerce & Subscription-Based Services',
                    desc: 'Online businesses can integrate Bookova with WooCommerce to handle service-based subscriptions, product rentals, and customized booking options.',
                },
            ],
            use_cases_list2: [
                {
                    name: 'Efficient Rental Management',
                    desc: 'Rental businesses, including vehicle rentals, vacation homes, and equipment leasing, can track availability, process payments, and manage customer reservations seamlessly.',
                },
                {
                    name: 'Travel & Hospitality Industry',
                    desc: 'Tour operators and travel agencies can manage package bookings, customer itineraries, and online payments, enhancing customer experience and business efficiency.',
                },
                {
                    name: 'Automated Customer Engagement & Retention',
                    desc: 'Businesses can use Bookova’s marketing and notification tools to send automated reminders, promotions, and personalized offers, ensuring higher customer retention.',
                },
            ],
            benefits: [
                {
                    name: 'Streamlined Booking Operations',
                    desc: 'Automate and simplify the entire booking process, reducing manual effort and increasing operational efficiency.',
                },
                {
                    name: 'Improved Security and Reliability',
                    desc: 'Protect customer data and transactions with advanced security features, ensuring compliance and trust.',
                },
                {
                    name: 'Increased Revenue Opportunities',
                    desc: 'Maximize bookings with a seamless customer experience, optimized scheduling, and integrated marketing tools.',
                },
                {
                    name: 'Scalability & Flexibility',
                    desc: 'Adapt the platform to business needs with modular features, allowing seamless expansion and customization.',
                },
                {
                    name: 'Enhanced Customer Experience',
                    desc: 'Offer a smooth and hassle-free booking journey with intuitive navigation, automated confirmations, and multiple payment options.',
                },
                {
                    name: 'Data-Driven Decision Making',
                    desc: 'Gain valuable insights through analytics on customer behavior, revenue trends, and booking performance for strategic growth.',
                },
            ]
        },
        '8': {
            name: 'EDURA',
            description: 'Edura is a comprehensive, user-centric Learning Management System (LMS) designed to simplify educational and operational processes. It integrates tools for managing students, staff, finances, and resources, all within a single platform. With advanced features and robust reporting, Edura empowers educational institutions to improve efficiency, collaboration, and decision-making.',
            logo: images.Edura,
            mdoule_image: images.EduraDashboard,
            segments: [
                {
                    name: 'Student Lifecycle Management',
                    desc: 'Seamlessly manage student inquiries, admissions, attendance, grades, and alumni tracking.',
                },
                {
                    name: 'Academic Tools',
                    desc: 'Advanced features for lesson planning, examinations and scheduling.',
                },
                {
                    name: 'Finance Management',
                    desc: 'Comprehensive modules for fee collection and financial tracking.',
                }
            ],
            features: [
                {
                    name: 'Comprehensive Student Lifecycle Management',
                    desc: 'Manage admissions, attendance, grades, and alumni in one system. Centralized data for seamless student tracking.',
                },
                {
                    name: 'Advanced Fee Management',
                    desc: 'Streamline fee collection with reminders and discounts. Track payments, dues, and financial reports efficiently.',
                },
                {
                    name: 'Integrated Academic Tools',
                    desc: 'Plan lessons, schedule classes, and manage syllabi effortlessly. Conduct online exams with built-in question banks and evaluation tools.',
                },
                {
                    name: 'Human Resource Management',
                    desc: 'Handle staff profiles, leave approvals, and performance ratings. Simplify HR workflows with customizable settings.',
                },
                {
                    name: 'Communication Tools',
                    desc: 'Centralized platform for email, SMS, and notice boards. Track communication history with integrated logs.',
                },
                {
                    name: 'Detailed Analytics and Reporting',
                    desc: 'Generate reports for academics, HR, finances, and inventory. Enable data-driven decisions with actionable insights.',
                },
            ],
            use_cases_list1: [
                {
                    name: 'Streamlined Admissions Process',
                    desc: 'Edura simplifies and automates the admission workflow, from inquiry tracking to enrollment confirmation, ensuring an efficient onboarding process for students.',
                },
                {
                    name: 'Centralized Resource Management',
                    desc: 'Manage library books, inventory, and study materials in a unified platform, ensuring organized and efficient access for students and staff.',
                },
                {
                    name: 'Examination and Result Automation',
                    desc: 'Plan, schedule, and conduct exams efficiently while generating results and customizable mark sheets with minimal effort.',
                },
            ],
            use_cases_list2: [
                {
                    name: 'Effective Parent-Teacher Communication',
                    desc: 'Enable seamless communication through integrated email and SMS tools, keeping parents informed about student progress and school updates.',
                },
                {
                    name: 'Attendance Monitoring',
                    desc: 'Track student and staff attendance in real-time, with options for approving leave and generating detailed attendance reports.',
                },
                {
                    name: 'Alumni Engagement',
                    desc: 'Build and maintain strong alumni networks through event management tools and data tracking, fostering lasting relationships with former students.',
                },
            ],
            benefits: [
                {
                    name: 'Streamlined Operations',
                    desc: 'Simplify administrative tasks such as admissions, attendance, and fee collection through centralized and automated workflows.',
                },
                {
                    name: 'Enhanced Academic Performance',
                    desc: 'Support educators and students with tools like lesson planning, homework management, and real-time progress tracking.',
                },
                {
                    name: 'Improved Resource Allocation',
                    desc: 'Optimize resource usage across staff, inventory, and transportation with comprehensive tracking and planning tools.',
                },
                {
                    name: 'Cost Efficiency',
                    desc: 'Reduce operational costs by digitizing processes like certificate generation, report management, and communication.',
                },
                {
                    name: 'Data-Driven Decisions',
                    desc: 'Leverage detailed reports and analytics to identify trends and make informed decisions to enhance institutional performance.',
                },
                {
                    name: 'Better Engagement & Communication',
                    desc: 'Strengthen relationships with students, staff, and parents through integrated email, SMS, and notice board communication tools.',
                },
            ]
        },
    };

    const product = productsData[productId];

    if (!product) {
        return <h2>Product not found</h2>;
    }

    return (
        <div className="space-y-4 w-screen">
            <MainLayout>
                <div>
                    <HeroSection name={product.name} description={product.description} logo={product.logo} />
                    <SegmentSection segments={product.segments} name={product.name} />
                    <FeaturesSection features={product.features} />
                    <UseCasesSection use_cases_list1={product.use_cases_list1} use_cases_list2={product.use_cases_list2} />
                    <OverviewSection mdoule_image={product.mdoule_image} />
                    <BenefitsSection benefits={product.benefits} />
                </div>
            </MainLayout>
        </div>
    );
}

export default ProductPage;
