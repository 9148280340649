import { Link } from "react-router-dom";
import { images } from "../../assets/images/images";
import Carousel from "../SharedComponents/carousel";
export default function GetInTouchSection() {
  const getInTouchcards = [
    {
      image: images.GetinTouchCall,
      title: "Call Us",
      description: "Phone: +94 77 733 3410",
    },
    {
      image: images.GetinTouchPen,
      title: "Write Us",
      description: "info@enigmasolutions.biz",
    },
    {
      image: images.GetinTouchLocation,
      title: "Visit Us",
      description: "No 51, Ivy Park, Thalawathugoda, Sri Lanka",
    },
  ];
  const GetTouchCard = ({ image, title, description, support }) => {
    return (
      <div className="w-full flex justify-center">
        <div className="bg-gradient-to-b from-[#0C0B22] via-[#0C162D] to-[#0A0919] h-[20rem] lg:h-[23rem] w-[100%] sm:w-[25rem] lg:w-[16rem] xl:w-[18rem] px-9 flex flex-col items-center justify-center text-white rounded-[20px] shadow-black shadow-2xl">
          <div className="h-[8rem] w-[8rem] rounded-[50%] bg-[#d9d9d9] flex justify-center items-center">
            <div className="h-[7rem] w-[7rem] rounded-[50%] bg-[#d9d9d9] border-[3px] border-[#1f2732] flex items-center justify-center">
              <img src={image} alt={title} className="w-[4rem] h-[4rem]" />
            </div>
          </div>
          <span className="text-center font-medium text-2xl sm:text-3xl  my-[1rem]">
            {title}
          </span>
          <span className="text-center py-1 font-normal text-sm sm:text-[20px] lg:text-[16px] ">
            {description}
          </span>
        </div>
      </div>
    );
  };
  return (
    <section className="flex flex-col items-center px-[10%]  ">
      <button className="text-[#71C25D] text-sm sm:text-xl h-[3.5rem] px-[2rem] rounded-[25px] shadow-slate-900 shadow-md hover:shadow-slate-900 hover:shadow-lg mt-[-1.5rem] bg-gradient-to-r from-[#1F2732] to-[#000000]">
        <Link to="/contact">GET STARTED</Link>
      </button>
      <div className="flex justify-center w-full xl:h-[8rem] my-[1rem] ">
        <div className="h-[5rem] xl:h-[8rem] xs:border-l-[3px] border-l-[4px] border-dashed border-black xs:w-[3px] w-[4px] "></div>
      </div>
      <span className="text-center text-[#00000] text-3xl sm:text-5xl xl:text-6xl  ">
        Get in Touch
      </span>
      <div className="flex justify-center w-full xl:h-[8rem] my-[1rem] ">
        <div className="h-[5rem] xl:h-[8rem] xs:border-l-[3px] border-l-[4px] border-dashed border-black xs:w-[3px] w-[4px] "></div>
      </div>
      <div className="lg:hidden w-full">
        <Carousel autoSlide={true}>
          {getInTouchcards.map((unitCard) => (
            <GetTouchCard {...unitCard} />
          ))}
        </Carousel>
      </div>
      <div className="hidden lg:flex w-full h-[25rem] justify-center items-center">
        {getInTouchcards.map((unitCard) => (
          <GetTouchCard {...unitCard} />
        ))}
      </div>
    </section>
  );
}
