import { useRef } from "react";
import CareerHeroSection from "../components/CareerPageComponents/CareerPageHeroSection";
import JobSection from "../components/CareerPageComponents/JobSelection";
import LifeAtEnigma from "../components/CareerPageComponents/LifeAtEnigma";
import MainLayout from "../components/SharedComponents/MainLayout";

export default function Career() {
  const jobSectionRef = useRef(null);

  const scrollToJobSection = () => {
    jobSectionRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <MainLayout>
      <CareerHeroSection onSeeOpeningsClick={scrollToJobSection} />
      <LifeAtEnigma />
      <JobSection ref={jobSectionRef} />
    </MainLayout>
  );
}
