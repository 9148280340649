import { axiosInstance } from "./BaseAPI";

export async function get_admin_profile_details() {
    try {
      return await axiosInstance.get("api/user");
    } catch (error) {
      console.error("User details Error:", error.response?.data || error);
      throw error;
    }
}

export async function admin_login(formData) {
    try {
      return await axiosInstance.post("api/login", formData);
    } catch (error) {
      console.error("Admin Login Error:", error.response?.data || error);
      throw error;
    }
}