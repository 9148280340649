import React, {useEffect} from 'react';
import MainLayout from "../components/SharedComponents/MainLayout";
import ApplicationSubmission from "../components/ApplicationPageComponents/ApplicationSubmission";

export default function AboutUs() {
  useEffect (()=>{
      window.scrollTo(0,0);
  })
  return (
    <MainLayout>
      <main>
        <ApplicationSubmission />
      </main>
    </MainLayout>
  );
}
