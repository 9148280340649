import React, {useEffect} from 'react';
import { useParams } from 'react-router-dom';
import Header from '../components/ServicePageComponents/Header';
import Offers from '../components/ServicePageComponents/Offers';
import WorkFlows from '../components/ServicePageComponents/WorkFlow';
import Strengths from '../components/ServicePageComponents/Strengths';
import MainLayout from '../components/SharedComponents/MainLayout';

import WebImage from "../assets/images/web.png";
import AutomateBG from "../assets/images/AutomateBG.png";
import WarehouseBG from "../assets/images/warehouseBG.png";

const ServicePage = () => {
    const { serviceId } = useParams();

    useEffect (()=>{
        window.scrollTo(0,0);
    })

    const servicesData = {
        '1': {
            name: 'Solutions Development',
            description: 'Our solutions development team crafts custom software and digital tools that align with your unique business goals, driving efficiency and fostering growth.',
            image: WebImage,
            offers: [
                'Customer Solutions Development',
                'Enterprise Resource Planning (ERP) Systems',
                'Business Process Automation',
                'Cloud-based Solutions'
            ],
            strength: [
                'Over 25 years of expertise in delivering innovative, scalable solutions',
                'Successfully implemented over 100+ business transformation projects',
                'Tailored solutions that adapt to your industry and organizational needs',
                'Dedicated team of developers and consultants to ensure seamless delivery'
            ],
            works: [
                {
                    name: 'Product Research & Strategy',
                    desc: 'We begin by understanding your business needs and analyzing existing processes to create a roadmap for success.',
                },
                {
                    name: 'Solution Design & Planning',
                    desc: 'We create system blueprints and wireframes, ensuring that your solution is designed to meet both your goals and user expectations.',
                },
                {
                    name: 'Development & Implementation',
                    desc: 'We use Agile methodologies to iteratively build and deploy your solution, ensuring it is robust, scalable, and user-friendly.',
                }
            ]
        },
        '2': {
            name: 'IT Automation',
            description: 'Empowering your organization with smart automation solutions that streamline processes, increase efficiency, and reduce human error.',
            image: AutomateBG,
            offers: [
                'Workflow Automation',
                'Robotic Process Automation (RPA)',
                'Systems Integrations & API Management',
                'IT Infrastructure Automation'
            ],
            strength: [
                'Proven track record in automating critical business processes',
                'Enhanced productivity and cost-saving automation solutions',
                'Comprehensive consulting and implementation support',
                'Experienced team specializing in cutting-edge automation technologies'
            ],
            works: [
                {
                    name: 'Process Mapping & Strategy',
                    desc: 'We analyze your workflows to identify opportunities for automation, enhancing efficiency and reducing manual tasks.',
                },
                {
                    name: 'Automation Design & Integration',
                    desc: 'Our experts design automated workflows and integrate them seamlessly into your existing systems.',
                },
                {
                    name: 'Continuous Improvement & Support',
                    desc: 'We continually monitor automated systems, refining and scaling them as your business grows, ensuring sustained performance.',
                }
            ]
        },
        '3': {
            name: 'Data Warehouse & Business Intelligence',
            description: 'We are passionate about crafting digital solutions that create successful brands and exceptional user experiences.',
            image: WarehouseBG,
            offers: [
                'Data Warehousing & Data Leaks',
                'Business Intelligence Reporting & Dashboards',
                'Data Integration & ETL Solutions',
                'Predictive Analytics & Machine Learning'
            ],
            strength: [
                'Expertise in building scalable data warehouses and BI platforms.',
                'Empowering businesses with real-time insights and data-driven decisions.',
                'Customized BI solutions tailored to your industry and needs.',
                'End-to-end support, from data integration to advanced analytics.'
            ],
            works: [
                {
                    name: 'Data Strategy & Assessment',
                    desc: 'We begin by assessing your current data landscape, identifying gaps, and defining a strategy to enhance your data management capabilities.',
                },
                {
                    name: 'Data Architecture & Integration',
                    desc: 'We design and implement robust data architectures and integrate multiple data sources for seamless reporting.',
                },
                {
                    name: 'Business Intelligence & Analytics',
                    desc: 'We help you leverage advanced BI tools and analytics platforms to visualize data, identify trends, and inform key business decisions.',
                }
            ]
        }
    };

    const service = servicesData[serviceId];

    if (!service) {
        return <h2>Service not found</h2>;
    }

    return (
        <div className="space-y-4 w-screen">
            <MainLayout>
                <div className='space-y-8'>
                    <Header name={service.name} description={service.description} image={service.image} />
                    <div className="flex justify-center w-full">
                        <div className="h-[3rem] xl:h-[4rem] xs:border-l-[2px] border-l-[3px] border-dashed border-black xs:w-[3px] w-[4px]"></div>
                    </div>
                    <Offers offers={service.offers} />
                    <div className="flex justify-center w-full">
                        <div className="h-[3rem] xl:h-[4rem] xs:border-l-[2px] border-l-[3px] border-dashed border-black xs:w-[3px] w-[4px]"></div>
                    </div>
                    <Strengths strengths={service.strength} />
                    <div className="flex justify-center w-full">
                        <div className="h-[3rem] xl:h-[4rem] xs:border-l-[2px] border-l-[3px] border-dashed border-black xs:w-[3px] w-[4px]"></div>
                    </div>
                    <WorkFlows works={service.works} />
                </div>
            </MainLayout>
        </div>
    );
}

export default ServicePage;
