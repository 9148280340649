import Carousel from "../SharedComponents/carousel";

const FeaturesSection = ({ features = [] }) => {
    return (
        <div className="bg-gradient-to-b from-[#0C0B22] via-[#0C162D] to-[#0A0919] w-screen h-auto pb-24 px-12 flex-col items-center justify-center">
            <div className="text-white font-customFont font-semibold text-center mb-6 py-8">
                <h1 className="text-4xl">Core Features</h1>
            </div>

            <div className="mt-[1rem]">
                <div className="sm:hidden">
                    <Carousel autoSlide={true}>
                        {features.map((feature, index) => (
                            <div key={index} className="flex items-center justify-center">
                                <div className="bg-[#D9D9D9] rounded-lg shadow-lg w-96 h-56 text-center flex flex-col items-center justify-center px-6 space-y-4">
                                    <h1 className="font-bold text-black text-xl">{feature.name}</h1>
                                    <p className="text-black">{feature.desc}</p>
                                </div>
                            </div>
                        ))}
                    </Carousel>
                </div>
                <div className="hidden sm:grid sm:grid-cols-2 xl:grid-cols-3 gap-4">
                    {features.map((feature, index) => (
                        <div key={index} className="flex items-center justify-center">
                            <div className="bg-[#D9D9D9] rounded-lg shadow-lg w-[500px] h-56 text-center flex flex-col items-center justify-center px-6 space-y-4">
                                <h1 className="font-bold text-black text-xl">{feature.name}</h1>
                                <p className="text-black">{feature.desc}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FeaturesSection;
