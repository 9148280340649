import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ImCross } from "react-icons/im";
import { create_blog, get_blogs } from "../../API/BlogAPI";

const Blogs = () => {
    const [blogs, setBlogs] = useState([]);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [image, setImage] = useState(null);
    const [showForm, setShowForm] = useState(false);

    useEffect(() => {
        fetchBlogs();
    }, []);

    const fetchBlogs = async () => {
        const response = await get_blogs();
        setBlogs(response.data);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("title", title);
        formData.append("description", description);
        if (image) formData.append("image", image);

        const response = await create_blog(formData);
        if (response.status === 200) {
            toast.success(response.data.message || "Blog created successfully!");
        } else {
            toast.error(response.data.message || "Something went wrong!");
        }

        fetchBlogs();
        setTitle("");
        setDescription("");
        setImage(null);
        setShowForm(false); 
    };

    return (
        <div className="min-h-screen bg-gray-50 p-6">
            <div className="container mx-auto bg-white p-8 rounded-lg shadow-md">
                <h2 className="text-3xl font-semibold text-gray-800 mb-6">Manage Blogs</h2>

                {!showForm && (
                    <button
                        onClick={() => setShowForm(true)}
                        className="py-2 px-4 bg-[#0C162D] bg-opacity-70 text-white font-semibold rounded-lg hover:bg-[#0C162D] focus:outline-none focus:ring-2 focus:ring-[#0e1933]"
                    >
                        Add New Blog
                    </button>
                )}

                {showForm && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                        <div className="bg-white p-8 rounded-lg shadow-md w-1/3">
                            <div className="flex justify-between">
                                <h3 className="text-2xl font-semibold text-gray-800 mb-6">Add New Blog</h3>
                                <ImCross onClick={() => setShowForm(false)} className="cursor-pointer" />
                            </div>
                            <form onSubmit={handleSubmit} className="space-y-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-600" htmlFor="title">
                                        Title
                                    </label>
                                    <input
                                        type="text"
                                        id="title"
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                        required
                                        className="w-full mt-2 px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-600" htmlFor="description">
                                        Description
                                    </label>
                                    <textarea
                                        id="description"
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                        required
                                        className="w-full mt-2 px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                                        rows="5"
                                    ></textarea>
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-600" htmlFor="image">
                                        Image
                                    </label>
                                    <input
                                        type="file"
                                        id="image"
                                        onChange={(e) => setImage(e.target.files[0])}
                                        className="w-full mt-2 file:border-none file:bg-blue-100 file:px-4 file:py-1 file:rounded-lg file:text-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
                                    />
                                </div>

                                <button
                                    type="submit"
                                    className="mt-4 py-2 px-4 bg-blue-600 text-white font-semibold rounded-[10px] hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-400"
                                >
                                    Add Blog
                                </button>
                            </form>
                        </div>
                    </div>
                )}
            </div>

            <div className="container mx-auto mt-10">
                <h3 className="text-2xl font-semibold text-gray-800 mb-4">Existing Blogs</h3>
                {blogs.length === 0 ? (
                    <p className="text-gray-600">No available blogs</p>
                ) : (
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6">
                        {blogs.map((blog) => (
                            <div key={blog.id} className="relative group overflow-hidden rounded-lg shadow-md">
                                <img
                                    src={blog.image}
                                    alt={blog.title}
                                    className="w-full h-64 object-cover transition-transform duration-300 group-hover:scale-105"
                                />
                                <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center px-6 group-hover:opacity-0">
                                    <h4 className="text-white text-center text-xl font-bold">{blog.title}</h4>
                                </div>
                                <div className="absolute inset-0 bg-black bg-opacity-70 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 p-4">
                                    <p className="text-white text-center text-sm">{blog.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Blogs;
