import { forwardRef } from "react";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const JobSection = forwardRef((props, ref) => {
  const [categories, setCategories] = useState([]);
  const [vacancies, setVacancies] = useState({});
  const navigate = useNavigate();

  const handleApply = (vacancy) => {
    navigate("/careers/apply", { state: { vacancy } });
  };

  useEffect(() => {
    fetchVacancies();
    fetchCategories();
  }, []);

  const fetchVacancies = async () => {
    const response = await axios.get("http://127.0.0.1:8000/api/vacancies");
    setVacancies(response.data);
  };

  const fetchCategories = async () => {
    const response = await axios.get("http://127.0.0.1:8000/api/vacancyCategories");
    setCategories(response.data);
  };

  const formatDate = (dateString) => {
      const date = new Date(dateString);

      return date.toLocaleString('en-US', { 
          year: 'numeric', 
          month: '2-digit', 
          day: '2-digit', 
          hour: '2-digit', 
          minute: '2-digit', 
          second: '2-digit', 
          hour12: true 
      });
  };

  return (
  <section ref={ref} className="px-[10%] w-full mb-10">
    <div className="h-[7rem] w-full flex items-center justify-left">
      <h1 className="text-left xs:text-xl text-3xl sm:text-4xl xl:text-5xl font-bold">
        Our Openings
      </h1>
    </div>
    <div className="container mx-auto mt-10"> 
          <div className="flex gap-2">
              <div className="bg-white shadow-lg p-2 w-[110px] border-2 border-solid border-gray-800 rounded-full hover:bg-gray-800 transition flex flex-col items-center justify-center cursor-pointer text-gray-800 hover:text-white">
                  <h3 className="text-md font-bold">View all</h3>
              </div>
            {categories.map((category) => (
              <div key={category.id} className="bg-white shadow-lg px-4 py-2 border-2 border-solid border-gray-800 rounded-full hover:bg-gray-800 transition flex flex-col items-center justify-center cursor-pointer text-gray-800 hover:text-white">
                  <h3 className="text-md font-bold">{category.category_name}</h3>
              </div>
            ))}
          </div>
    </div>

    <div className="container mx-auto mt-10">
                <ul>
                  {vacancies.length > 0 ? (
                    vacancies.map((vacancy) => (
                      <li key={vacancy.id} className="border-b-[2px] border-b-gray-300 pb-4 mb-3"> 
                        <div className="flex justify-between">
                          <h3 className="text-2xl font-bold text-gray-800">{vacancy.title}</h3>
                          <button className="px-4 py-2 border-2 border-solid text-gray-800 hover:text-white border-gray-800 hover:bg-gray-800 rounded-full"
                                  onClick={() => handleApply(vacancy)}>Apply</button>
                        </div>
                        <p>{vacancy.description}</p>
                        <p><strong>Category:</strong> {vacancy.category_name}</p>
                        <p><strong>Posted on:</strong> {formatDate(vacancy.created_at)} </p>
                      </li>
                    ))
                  ) : (
                    <p>No vacancies available</p>
                  )}
                </ul>
    </div>
  </section>
  );
});

export default JobSection;
