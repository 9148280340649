

const OverviewSection = ({ mdoule_image }) => {
    return (
        <div className="bg-gradient-to-b from-[#0C0B22] via-[#0C162D] to-[#0A0919] w-screen h-auto pb-24 px-12 flex-col items-center justify-center">
            <div className="text-white font-customFont font-semibold text-center mb-6 py-8">
                <h1 className="text-4xl">Platform Module Overview</h1>
            </div>

            <div className="mt-[1rem] w-full flex items-center justify-center">
                <div className='w-full md:w-[80%] flex flex-col md:flex-row items-center justify-between'>
                    <div className="w-full md:w-[50%] h-full flex justify-center items-center">
                        <img
                            className="w-60 h-auto md:w-72 md:h-68 lg:w-[800px] lg:h-76"
                            src={mdoule_image}
                            alt={"name"}
                        />
                    </div>

                    <div className="w-full md:w-[50%] space-y-8 py-4 md:py-12 px-10 md:px-4 lg:px-12 flex flex-col justify-center items-center md:items-start text-white">
                            <h2 className="text-center md:text-left font-bold text-4xl lg:text-5xl">Dashboard Overview</h2>
                            <p className="text-center md:text-left font-light text-md lg:text-lg xl:text-xl">"description"</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OverviewSection;
