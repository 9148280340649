import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ImCross } from "react-icons/im";
import { get_events, create_event } from "../../API/EventAPI";

const Events = () => {
    const [events, setEvents] = useState([]);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [date, setDate] = useState("");
    const [images, setImages] = useState([]);
    const [showForm, setShowForm] = useState(false);

    useEffect(() => {
        fetchEvents();
    }, []);

    const fetchEvents = async () => {
        const response = await get_events();
        setEvents(response.data);
    };

    const handleImageChange = (e) => {
        const selectedImages = Array.from(e.target.files);
        setImages([...images, ...selectedImages]);
    };

    const handleImageRemove = (index) => {
        const updatedImages = images.filter((_, i) => i !== index);
        setImages(updatedImages);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("title", title);
        formData.append("description", description);
        formData.append("date", date);

        for (let i = 0; i < images.length; i++) {
            formData.append("images[]", images[i]);
        }

        console.log(images)

        const response = await create_event(formData);
        if (response.status === 200) {
            toast.success(response.data.message || "Event created successfully!");
        } else {
            toast.error(response.data.message || "Something went wrong!");
        }

        fetchEvents();
        setTitle("");
        setDescription("");
        setDate("");
        setImages([]);
        setShowForm(false);
    };

    const handleViewAlbum = (eventId) => {
      window.location.href = `/event-album/${eventId}`;
  };

    return (
        <div className="min-h-screen bg-gray-50 p-6">
            <div className="container mx-auto bg-white p-8 rounded-lg shadow-md">
                <h2 className="text-3xl font-semibold text-gray-800 mb-6">Manage Event Albums</h2>

                {!showForm && (
                    <button
                        onClick={() => setShowForm(true)}
                        className="py-2 px-4 bg-[#0C162D] bg-opacity-70 text-white font-semibold rounded-lg hover:bg-[#0C162D] focus:outline-none focus:ring-2 focus:ring-[#0e1933]"
                    >
                        Add New Event Album
                    </button>
                )}

                {showForm && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                        <div className="bg-white p-8 rounded-lg shadow-md w-1/3">
                            <div className="flex justify-between">
                                <h3 className="text-2xl font-semibold text-gray-800 mb-6">Add New Album</h3>
                                <ImCross onClick={() => setShowForm(false)} className="cursor-pointer" />
                            </div>
                            <form onSubmit={handleSubmit} className="space-y-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-600" htmlFor="title">
                                        Title
                                    </label>
                                    <input
                                        type="text"
                                        id="title"
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                        required
                                        className="w-full mt-2 px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-600" htmlFor="description">
                                        Description
                                    </label>
                                    <textarea
                                        id="description"
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                        required
                                        className="w-full mt-2 px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                                        rows="5"
                                    ></textarea>
                                </div>

                                <div>
                                  <label className="block text-sm font-medium text-gray-600" htmlFor="date">
                                      Date
                                  </label>
                                  <input
                                      type="date"
                                      id="date"
                                      value={date}
                                      onChange={(e) => setDate(e.target.value)}
                                      required
                                      className="w-full mt-2 px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                                  />
                              </div>

                              <div>
                                  <label className="block text-sm font-medium text-gray-600" htmlFor="images">
                                      Event Images (Drag and Drop or Select)
                                  </label>
                                  <input
                                      type="file"
                                      id="images"
                                      multiple
                                      onChange={handleImageChange}
                                      className="w-full mt-2 file:border file:bg-blue-100 file:px-4 file:py-2 file:rounded-lg file:text-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
                                  />

                                  <div className="mt-4">
                                    {images.length > 0 && (
                                        <div className="grid grid-cols-3 gap-4">
                                            {images.map((image, index) => (
                                                <div key={index} className="relative">
                                                    <img
                                                        src={URL.createObjectURL(image)}
                                                        alt="preview"
                                                        className="w-32 h-32 object-cover rounded-lg"
                                                    />
                                                    <button
                                                        type="button"
                                                        onClick={() => handleImageRemove(index)}
                                                        className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1 text-xs"
                                                    >
                                                        X
                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                  </div>
                              </div>

                                <button
                                    type="submit"
                                    className="mt-4 py-2 px-4 bg-blue-600 text-white font-semibold rounded-[10px] hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-400"
                                >
                                    Add Album
                                </button>
                            </form>
                        </div>
                    </div>
                )}
            </div>

            <div className="mt-10">
              <h3 className="text-2xl font-semibold text-gray-800 mb-4">Existing Events</h3>
              {events.length === 0 ? (
                  <p className="text-gray-600">No events available</p>
              ) : (
                  <ul className="space-y-6">
                      {events.map((event) => (
                          <li 
                              key={event.event_id} 
                              className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow flex items-center space-x-6"
                          >
                              <div className="w-[200px] h-[200px] flex-shrink-0">
                                  <img
                                      src={event.featured_image}
                                      alt="event"
                                      className="w-full h-full object-cover rounded-lg shadow-md"
                                  />
                              </div>

                              <div className="flex-1">
                                  <h4 className="text-xl font-bold text-gray-800">{event.title}</h4>
                                  <p className="text-lg font-semibold text-gray-500 mt-2">{event.date}</p>
                                  <p className="text-sm text-gray-600 mt-2">{event.description}</p>

                                  <button 
                                      className="mt-4 px-4 py-2 border-2 border-[#0C162D] text-[#0C162D] text-sm font-semibold rounded-lg shadow-md hover:scale-105 transition-all duration-500"
                                      onClick={() => handleViewAlbum(event.event_id)}
                                  >
                                      View Album
                                  </button>
                              </div>
                          </li>
                      ))}
                  </ul>
              )}
          </div>
        </div>
    );
};

export default Events;
