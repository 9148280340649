import MainLayout from "../components/SharedComponents/MainLayout";
import HeaderSection from "../components/BlogsPageComponents/HeaderSection";

export default function AboutUs() {
  return (
    <MainLayout>
      <main>
        <HeaderSection />
      </main>
    </MainLayout>
  );
}
