import { images } from "../../assets/images/images";

const Footer = () => {
  return (
    <div className="relative bottom-0 left-0 w-screen">
      <div className="flex justify-center w-full  xl:h-[6rem] mb-5">
        <div className="h-[4rem] lg:h-[4rem] xl:h-[6rem] xs:border-l-[3px] border-l-[4px] border-dashed border-black xs:w-[3px] w-[4px] "></div>
      </div>
      <svg
        className="w-full   h-auto "
        viewBox="0 0 1440 340"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 144.038L718.5 50L1440 144.038V340L0 340L0 144.038Z"
          fill="#1F2732"
        />
        <circle cx="720" cy="50" r="50" fill="#71C25D" />
      </svg>

      <div className="absolute inset-x-0 bottom-0 sm:bottom-2 md:bottom-4 lg:bottom-12 flex lg:h-[6rem] lg:flex-col items-center justify-between lg:justify-center text-white text-center px-[10%] space-y-1 sm:space-y-1 md:space-y-2 lg:space-y-4 xl:space-y-4 2xl:space-y-4 z-10 pb-3">
        <div>
          <p className="text-xs/[12px] sm:text-lg md:text-xl lg:text-4xl font-bold">
            Enigma Solutions{" "}
          </p>
        </div>
        <div className="text-xs/[8px] flex flex-wrap justify-center gap-4 mt-2">
          <a
            href="mailto:info@enigmasolutions.biz"
            className="w-8 h-8 sm:w-10 sm:h-10 rounded-full bg-gray-800 flex items-center justify-center hover:bg-gray-700 transition-colors duration-300"
          >
            <img
              src={images.GmailIcon}
              alt=""
              className="w-4 h-4 sm:w-5 sm:h-5 md:w-6 md:h-6 "
            />
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=61564455080838&mibextid=ZbWKwL"
            className="w-8 h-8 sm:w-10 sm:h-10 rounded-full bg-gray-800 flex items-center justify-center hover:bg-gray-700 transition-colors duration-300"
          >
            <svg
              className="w-4 h-4 sm:w-5 sm:h-5 md:w-6 md:h-6 fill-current text-blue-800"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path d="M22 0H2C.9 0 0 .9 0 2v20c0 1.1.9 2 2 2h11v-9H9v-4h4V7.5c0-3.1 2.5-5.5 5.5-5.5H20v4h-1c-1.1 0-2 .9-2 2V9h4l-1 4h-3v9h5c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2z" />
            </svg>
          </a>
          <a
            href="https://www.linkedin.com/company/enigma-solutions-pvtltd/"
            className="w-8 h-8 sm:w-10 sm:h-10 rounded-full bg-gray-800 flex items-center justify-center hover:bg-gray-700 transition-colors duration-300"
          >
            <svg
              className="w-4 h-4 sm:w-5 sm:h-5 md:w-6 md:h-6 fill-current text-blue-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path d="M22.23 0H1.77C.8 0 0 .8 0 1.77v20.46C0 23.2.8 24 1.77 24h20.46c.97 0 1.77-.8 1.77-1.77V1.77C24 .8 23.2 0 22.23 0zM7.2 20.45H3.56V9h3.64v11.45zm-1.82-13a2.1 2.1 0 110-4.2 2.1 2.1 0 010 4.2zM20.45 20.45h-3.64v-5.58c0-1.34-.03-3.07-1.88-3.07-1.88 0-2.17 1.47-2.17 2.98v5.67H9.11V9h3.5v1.56h.05c.49-.93 1.7-1.88 3.5-1.88 3.75 0 4.44 2.47 4.44 5.68v6.09z" />
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
