import Carousel from "../SharedComponents/carousel";

const SegmentSection = ({ segments = [], name }) => {
    return (
        <div className="bg-gradient-to-b from-[#0C0B22] via-[#0C162D] to-[#0A0919] w-screen h-auto py-8 px-12 flex-col items-center justify-center mt-10">
            <div className="text-white font-customFont font-semibold text-center space-y-6 mb-6 py-8">
                <h1 className="text-4xl">Key Segments</h1>
                <p className="text-xl sm:text-2xl"><span>{name}</span> caters to a variety of industries and business types </p>
            </div>

            <div className="mt-[1rem]">
                <div className="lg:hidden">
                    <Carousel autoSlide={true}>
                        {segments.map((segment, index) => (
                            <div key={index} className="flex items-center justify-center">
                                <div className="bg-[#0EAC3B] rounded-lg shadow-lg w-96 h-56 text-center flex flex-col items-center justify-center px-6 space-y-4">
                                    <h1 className="font-bold text-white text-xl">{segment.name}</h1>
                                    <p className="text-black">{segment.desc}</p>
                                </div>
                            </div>
                        ))}
                    </Carousel>
                </div>
                <div className="hidden lg:flex h-[16rem] justify-between items-center 2xl:px-[10%]">
                    {segments.map((segment, index) => (
                        <div key={index} className="">
                            <div className="bg-[#0EAC3B] rounded-lg shadow-lg w-72 xl:w-96 h-56 text-lg text-center flex flex-col items-center justify-center px-6 space-y-4">
                                <h1 className="font-bold text-white text-2xl">{segment.name}</h1>
                                <p className="text-black">{segment.desc}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className="flex justify-center w-full mt-4">
                <div className="h-[3rem] xl:h-[4rem] xs:border-l-[2px] border-l-[3px] border-dashed border-white xs:w-[3px] w-[4px]"></div>
            </div>
        </div>
    );
};

export default SegmentSection;
